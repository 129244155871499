export class ClientsRequest {
  page: number = 0;
  pageSize: number = 25;
  totalPages: number;
  query: string;
  sortField: string = 'created';
  ascendingSorting: boolean = false;
  filterByAdvisorId: number = null;
  confirmedConsent: boolean = true;
  leadsOnly: boolean = false;
}
