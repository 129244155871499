import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { DashboardStatistic } from '../model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public statistics(): Observable<DashboardStatistic> {
    return this.http.get<DashboardStatistic>(`${environment.apiEndpoint}/b2b/dashboard/stream`);
  }
}
