import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
  SharedVideo,
  SharedVideoRequest,
  SharedVideoResponse,
  UpdateVideoModel,
  VideoDetailDto,
  VideoListRequest,
  VideoListResponse,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class MediaStudioService {
  constructor(private http: HttpClient) {}

  public createVideo(title: string, description: string): Observable<any> {
    return this.http.post(`${environment.apiEndpoint}/media-studio/video`, {
      title: title,
      description: description,
    });
  }

  public updatePoster(id: number, poster: File, type: string): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': type,
    });

    return this.http.put(`${environment.apiEndpoint}/media-studio/video/${id}/poster`, poster, {
      headers,
      responseType: 'text',
    });
  }

  public uploadVideo(uploadUrl: string, file: File): Observable<number> {
    return new Observable<number>((observer: Subscriber<number>): void => {
      const request: XMLHttpRequest = new XMLHttpRequest();

      request.upload.addEventListener('progress', (event): void => {
        if (event.lengthComputable) {
          const percent: number = (event.loaded / event.total) * 100;
          observer.next(Math.round(percent));
        }
      });

      request.addEventListener('loadend', (): void => {
        observer.complete();
      });

      request.addEventListener('error', (): void => {
        observer.error('error');
      });

      const type: string[] = file.type.split(';');
      const shortType: string = type[0].trim();

      request.open('PUT', uploadUrl);
      request.setRequestHeader('Content-Type', shortType);
      request.setRequestHeader('X-Amz-Acl', 'private');
      request.send(file);
    });
  }

  public updateVideo(id: number, body: UpdateVideoModel): Observable<any> {
    return this.http.put(`${environment.apiEndpoint}/media-studio/video/${id}`, body);
  }

  public restartUploadVideo(id: number): Observable<string> {
    return this.http.put(`${environment.apiEndpoint}/media-studio/video/${id}/restart`, null, { responseType: 'text' });
  }

  public getListVideos(videoRequest: VideoListRequest): Observable<VideoListResponse> {
    const params: HttpParams = new HttpParams()
      .set('page', videoRequest.page.toString())
      .set('page_size', videoRequest.page_size.toString());

    return this.http.get<VideoListResponse>(`${environment.apiEndpoint}/media-studio/video`, { params });
  }

  public getVideoById(id: number): Observable<VideoDetailDto> {
    return this.http.get<VideoDetailDto>(`${environment.apiEndpoint}/media-studio/video/${id}`);
  }

  public deleteVideo(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/media-studio/video/${id}`);
  }

  public getSharedVideoList(sharedVideoRequest: SharedVideoRequest): Observable<SharedVideoResponse> {
    const params: HttpParams = new HttpParams()
      .set('owner_id', sharedVideoRequest.owner_id)
      .set('page', sharedVideoRequest.page.toString())
      .set('page_size', sharedVideoRequest.page_size.toString());

    return this.http.get<SharedVideoResponse>(`${environment.apiEndpoint}/media-studio/b2b/video/share`, { params });
  }

  public getMultipleSharedVideos(ids: number[]): Observable<SharedVideo[]> {
    return this.http.get<SharedVideo[]>(
      `${environment.apiEndpoint}/media-studio/b2b/video/share/multi?${ids
        .map((id: number): string => `id=${id}`)
        .join('&')}`,
    );
  }

  public getSharedVideoById(id: number): Observable<SharedVideo> {
    return this.http.get<SharedVideo>(`${environment.apiEndpoint}/media-studio/b2b/video/share/${id}`);
  }
}
