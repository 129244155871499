import { InsuranceGroup, InsuranceProvider } from './insurance.model';
import { Person } from './auth/person';

export class DataSet {
  id: number;
  dataType: string;
  importingStatus: string;
  imported: boolean;
  created: Date;
}

export class OperationResponse {
  exceptions: Array<ExceptionResult> = new Array<ExceptionResult>();
}

export class ExceptionResult {
  id: string;
  message: string;
}

export class ImportedClientRequest {
  offset: number = 0;
  limit: number = 10;
  sortField: string = 'id';
  ascendingSorting: boolean = true;
}

export class ImportedClient {
  id: number;
  externalId: string;
  personId: number;
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  birthday: string | Date;
  phone: string;
  brokerMandateAvailable: boolean;
}

export class ClientsImportData {
  clientId: number;
  personalInvite: boolean = false;
  sendInvite: boolean = false;
  personalText: string = null;
}

export class ImportedClientResponse {
  offset: number;
  limit: number;
  totalPages: number;
  totalElements: number;
  totalMatchedOnes: number;
  totalItems: number;
  dataSet: DataSet;
  clients: Array<ImportedClient>;
}

export class ImportedContractRequest {
  offset: number = 0;
  limit: number = 25;
  sortField: string = 'id';
  ascendingSorting: boolean = true;
}

export class ImportedContract {
  id: number;
  externalId: string;
  contractNumber: string;
  amount: number;
  contractStartDate: Date;
  contractEndDate: Date;
  created: Date;
  insuranceGroup: InsuranceGroup;
  insuranceProvider: InsuranceProvider;
  contractUid: string;
  client: Person;
  readyForDeletion: boolean;
}

export class ImportedContractResponse {
  offset: number;
  limit: number;
  totalPages: number;
  totalElements: number;
  totalMatchedOnes: number;
  totalItems: number;
  dataSet: DataSet;
  contracts: Array<ImportedContract>;
}
