export class Transaction {
    description: string;
    subTitle: string;
    bookedAt: Date;
    type: string;
    category: string;
    status: string;
    amount: string;
    currency: string;
    providerMutability: string;
}
