import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
  Contract,
  ContractGroup,
  ClientContracts,
  ContractAdvice,
  CustomerMatchingReport,
  ContractResponse,
  ContractOverviewRequest,
  ContractOverviewResponse,
  ContractOverviewCriteria,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class ContractsManagerService {
  public isOtherContractOpened: boolean = false;

  constructor(private http: HttpClient) {}

  public load(contractUid: string): Observable<ContractResponse> {
    return this.http.get<ContractResponse>(`${environment.apiEndpoint}/b2b/contractsmanager/load/${contractUid}`);
  }

  public loadContractAttachments(contractUid: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/contractsattachment/${contractUid}`);
  }

  public loadClientContracts(clientUid: string): Observable<ClientContracts> {
    return this.http.get<ClientContracts>(
      `${environment.apiEndpoint}/b2b/contractsmanager/clientcontracts/${clientUid}`,
    );
  }

  public contractGroups(): Observable<Array<ContractGroup>> {
    return this.http.get<Array<ContractGroup>>(`${environment.apiEndpoint}/b2b/contractsmanager/contractgroups`);
  }

  public saveAdvice(contractAdvice: ContractAdvice): Observable<Contract> {
    return this.http.post<Contract>(`${environment.apiEndpoint}/b2b/rating/advice`, contractAdvice);
  }

  public saveContract(contract: Contract): Observable<Contract> {
    return this.http.post<Contract>(`${environment.apiEndpoint}/b2b/contractsmanager/save`, contract);
  }

  public removeContract(contractUid: string): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2b/contractsmanager/archive/${contractUid}`);
  }

  public exportToPool(contractUid: string | undefined): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2b/contractsmanager/create/pool/${contractUid}`);
  }

  public synchroniseAll(clientId: string): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2b/contractsmanager/synchronise/${clientId}`);
  }

  public synchContract(contractId: number): Observable<CustomerMatchingReport> {
    return this.http.get<CustomerMatchingReport>(
      `${environment.apiEndpoint}/b2b/netfonds/synch/contract/${contractId}`,
    );
  }

  public deleteContract(contractUid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2b/contractsmanager/delete/${contractUid}`);
  }

  public getLastCreatedContracts(size: number): Observable<Contract[]> {
    return this.http.get<Contract[]>(`${environment?.apiEndpoint}/b2b/contractsmanager/advisorcontracts?size=${size}`);
  }

  // get all contracts for simple admin
  public getAllContractByCompanyId(
    companyId: number,
    body: ContractOverviewRequest,
  ): Observable<ContractOverviewResponse> {
    return this.http.post<ContractOverviewResponse>(
      `${environment?.apiEndpoint}/b2b/company/${companyId}/advisor/users/contracts`,
      body,
    );
  }

  // get all contracts for company admin
  public getAllContractByAdvisorId(
    advisorId: number,
    body: ContractOverviewRequest,
  ): Observable<ContractOverviewResponse> {
    return this.http.post<ContractOverviewResponse>(
      `${environment?.apiEndpoint}/b2b/advisor/${advisorId}/users/contracts`,
      body,
    );
  }

  // download scv by request param
  public getCSVByCompanyId(companyId: number, body: ContractOverviewCriteria[]): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'text/csv',
    });

    return this.http.post<Blob>(
      `${environment?.apiEndpoint}/b2b/company/${companyId}/advisor/users/contracts/export`,
      body,
      { headers, responseType: 'blob' as 'json' },
    );
  }

  public getCSVByByAdvisorId(advisorId: number, body: ContractOverviewCriteria[]): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'text/csv',
    });

    return this.http.post<Blob>(`${environment?.apiEndpoint}/b2b/advisor/${advisorId}/users/contracts/export`, body, {
      headers,
      responseType: 'blob' as 'json',
    });
  }
}
