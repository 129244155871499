import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ActivityAdvisorsRequest, ActivityAdvisorsResponse, ActivityRequest, ActivityResponse } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(private http: HttpClient) {}

  public getActivity(request: ActivityRequest): Observable<ActivityResponse> {
    return this.http.post<ActivityResponse>(`${environment.apiEndpoint}/activity/b2b/search/log`, request);
  }

  public getActivityAdvisors(request: ActivityAdvisorsRequest): Observable<ActivityAdvisorsResponse> {
    return this.http.post<ActivityAdvisorsResponse>(`${environment.apiEndpoint}/activity/b2b/search/advisor`, request);
  }
}
