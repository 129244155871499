import { Client } from '../auth/client';
import { ClientDetails } from './client-detail-response';

export class RecipientsResponse {
  clients: Array<ClientDetails> = new Array<ClientDetails>();
  offset: number;
  limit: number;
  totalPages: number;
  totalHits: number = 0;
}
