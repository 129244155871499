import { RecipientsFilter } from './recipients-filter';
import { Attachment } from '../attachment.model';

export class Campaign {
  id: number;
  name: string;
  subject: string;
  description: string;
  campaignType: any = null;
  draft: boolean = false;
  created: Date;
  recipientsAmount = 0;
  recipientsFilter: RecipientsFilter = null;
  excludedCustomers: Array<number> = new Array();
  outsideFilterCustomerIds: Array<number> = new Array();
  attachments: Array<Attachment> = new Array();
}
