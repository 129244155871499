import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, tap } from 'rxjs';

import { environment } from '../../../environments/environment';

import { BrokerMandateApprovalModel, BrokerMandateApprovalRequest, BrokerMandateApprovalResponse } from '../model';

@Injectable({
  providedIn: 'root',
})
export class BrokerMandateApprovalService {
  public mandateDataChangedSubject = new Subject();

  constructor(private http: HttpClient) {}

  public pending(): Observable<BrokerMandateApprovalModel[]> {
    return this.http.get<BrokerMandateApprovalModel[]>(`${environment.apiEndpoint}/b2b/brokermandateapproval/pending`);
  }

  public countPending(): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(`${environment.apiEndpoint}/b2b/brokermandateapproval/countpending`);
  }

  public loadMandates(request: BrokerMandateApprovalRequest): Observable<BrokerMandateApprovalResponse> {
    return this.http.post<BrokerMandateApprovalResponse>(
      `${environment.apiEndpoint}/b2b/brokermandateapproval`,
      request,
    );
  }

  public setStatus(brokerMandateApprovalModel: BrokerMandateApprovalModel): Observable<any> {
    return this.http
      .post<any>(`${environment.apiEndpoint}/b2b/brokermandateapproval/setstatus`, brokerMandateApprovalModel)
      .pipe(
        tap((): void => {
          if (brokerMandateApprovalModel.status === 'APPROVED') {
            this.mandateDataChangedSubject.next(true);
          }
        }),
      );
  }

  public synchorinseMandate(personId: number): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2b/brokermandateapproval/synch/${personId}`);
  }

  public resetMandateStatus(personId: number, exist: boolean): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2b/person/mandate/${personId}/${exist}`);
  }
}
