import { ClientInvitation } from '../client-invitation';

export class ClientsInvitationResponse {
  clientInvitations: Array<ClientInvitation>;
  limit: number;
  offset: number;
  totalElements: number;
  totalPages: number;
}

export class ClientInviteClientResponse {
  invitedBy: InvitedByModel[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class InvitedByModel {
  id: number;
  firstName: string;
  lastName: string;
  collapsed?: boolean = false;
  referralsList: ReferralListModel[];
}

export class ReferralListModel {
  id: number;
  email: string;
  registeredAt: Date;
  created: Date;
}
