import { Advisor } from './advisor';

export class BrokerMandate {
  id: number;
  brokerMandate: string;
  powerOfAttorney: string;
  privacyConsent: string;
  mandateTypeEnum: string;
  active: boolean;
  signatureBase64: string;
  signedBy: Advisor;
  created: Date;
}
