import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './app-layout.component';

import { RoleGuard } from '../shared/guards';

import { Roles } from '../shared/enum/roles.enum';
import { Groups } from '../shared/enum/groups.enum';

// Use roles for provide individual access to routes by specific roles.
const roles = Roles;
const groups = Groups;

const layoutRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [RoleGuard],
        data: { roles: [] },
      },
      {
        path: 'clients',
        loadChildren: () => import('../pages/clients/clients.module').then((m) => m.ClientsModule),
        canActivate: [RoleGuard],
        data: { roles: [] },
      },
      {
        path: 'news',
        loadChildren: () => import('../pages/news/news.module').then((m) => m.NewsModule),
        canActivate: [RoleGuard],
        data: { roles: [Roles.CHAT] },
      },
      {
        path: 'campaign',
        loadChildren: () => import('../pages/campaign/campaign.module').then((m) => m.CampaignModule),
        canActivate: [RoleGuard],
        data: { roles: [Roles.CAMPAIGN] },
      },
      {
        path: 'calendar',
        loadChildren: () => import('../pages/calendar/custom-calendar.module').then((m) => m.CustomCalendarModule),
        canActivate: [RoleGuard],
        data: { roles: [] },
      },
      {
        path: 'activity',
        loadChildren: () => import('../pages/activity/activity.module').then((m) => m.ActivityModule),
        canActivate: [RoleGuard],
        data: { roles: [] },
      },
      {
        path: 'analyse',
        loadChildren: () => import('../pages/analyse/analyse.module').then((m) => m.AnalyseModule),
        canActivate: [RoleGuard],
        data: { roles: [] },
      },
      {
        path: 'contracts-overview',
        loadChildren: () =>
          import('../pages/contracts-overview/contracts-overview.module').then((m) => m.ContractsOverviewModule),
        canActivate: [],
        data: { roles: [] },
      },
      {
        path: 'settings',
        loadChildren: () => import('../pages/profile/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [RoleGuard],
        data: { roles: [] },
      },
      {
        path: 'mandate',
        loadChildren: () =>
          import('../pages/profile/mandate-data/mandate-data.module').then((m) => m.MandateDataModule),
        canActivate: [RoleGuard],
        data: { roles: [Roles.MANDATE] },
      },
      {
        path: 'profile-tab',
        loadChildren: () => import('../pages/profile/profile-tab/profile-tab.module').then((m) => m.ProfileTabModule),
        canActivate: [RoleGuard],
        data: { roles: [] },
      },
      // {
      //   path: 'advisors-table',
      //   loadChildren: () =>
      //     import('../pages/profile/advisors-table/advisors-table.module').then((m) => m.AdvisorsTableModule),
      //   canActivate: [RoleGuard],
      //   data: { roles: [] },
      // },
      {
        path: 'pools',
        loadChildren: () =>
          import('../pages/profile/pool-connection/pool-connection.module').then((m) => m.PoolConnectionModule),
        canActivate: [RoleGuard],
        data: { roles: [Roles.POOL_CONNECTION] },
      },
      {
        path: 'media-studio',
        loadChildren: () => import('../pages/media-studio/media-studio.module').then((m) => m.MediaStudioModule),
        canActivate: [RoleGuard],
        data: { roles: [Roles.MEDIA_STUDIO] },
      },
      {
        path: 'data-advisors-table',
        loadChildren: () =>
          import('../pages/profile/data-importer/data-importer.module').then((m) => m.DataImporterModule),
        canActivate: [RoleGuard],
        data: { roles: [Roles.DATA_IMPORTER] },
      },
      {
        path: 'broker-mandate-approvals',
        loadChildren: () =>
          import('../pages/broker-mandate-approvals/broker-mandate-approvals.module').then(
            (m) => m.BrokerMandateApprovalsModule,
          ),
        canActivate: [RoleGuard],
        data: { roles: [Roles.MANDATE] },
      },
      {
        path: 'subscription-manager',
        loadChildren: () =>
          import('../pages/profile/subscription-manager/subscription-manager.module').then(
            (m) => m.SubscriptionManagerModule,
          ),
        canActivate: [RoleGuard],
        data: { roles: [Roles.COMPANY_ADMIN] },
      },
      {
        path: 'subscription-creation',
        loadChildren: () =>
          import('../pages/profile/subscription-creation/subscription-creation.module').then(
            (m) => m.SubscriptionCreationModule,
          ),
        canActivate: [RoleGuard],
        data: { roles: [Roles.COMPANY_ADMIN] },
      },
      {
        path: 'subscription-edit/:id',
        loadChildren: () =>
          import('../pages/profile/subscription-edit/subscription-edit.module').then((m) => m.SubscriptionEditModule),
        canActivate: [RoleGuard],
        data: { roles: [Roles.COMPANY_ADMIN] },
      },
      {
        path: 'subscription-payment-error',
        loadChildren: () =>
          import('../pages/profile/subscription-payment-error/subscription-payment-error.module').then(
            (m) => m.SubscriptionPaymentErrorModule,
          ),
        canActivate: [RoleGuard],
        data: { roles: [Roles.COMPANY_ADMIN] },
      },
      {
        path: 'restricted-product',
        loadChildren: () =>
          import('../pages/restricted-product/restricted-product.module').then((m) => m.RestrictedProductModule),
        canActivate: [RoleGuard],
        data: { roles: [] },
      },
    ],
  },
];

export const ROUTES = RouterModule.forChild(layoutRoutes);
