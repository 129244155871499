import { Pool } from './pool';

export class PoolConnection {
  active: boolean;
  advisorId: string;
  advisorKeycloakId: string;
  id: number;
  username: string;
  password: string;
  domain: string;
  pool: Pool;
  selected?: boolean = false;
}
