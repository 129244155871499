import { PeriodOfNoticeEnum } from '../../enum/period-of-notice.enum';

export class TerminationDetails {
  id: string;
  created: Date;
  note: string;
  active: boolean = false;
  reminderDate: Date;
  periodOfNotice: PeriodOfNoticeEnum;
}
