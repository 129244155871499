import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MandateUpdateService {
  private mandate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  public getUpdatedMandate(): Observable<boolean> {
    return this.mandate.asObservable();
  }

  public setUpdatedMandate(value: boolean): void {
    this.mandate.next(value);
  }
}
