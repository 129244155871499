export class NewsMessages {
  clientId: number;
  headLine: string;
  totalMessages: number;
  unreadMessages: number;
  clientProfilePictureUrl: string | null;
  clientFirstName: string;
  clientLastName: string;
  created: string;
  isLiked?: boolean;
}

export class News {
  offset: number;
  limit: number;
  totalPages: number;
  totalElements: number;
  messages: NewsMessages[];
}
