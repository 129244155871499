// Generated using typescript-generator version 3.0.1157 on 2023-09-14 13:56:47.
/**
 * The response when a video was created
 */
export interface VideoCreatedDto {
  /**
   * The id of the video
   */
  readonly id: number;
  /**
   * The URL for the upload
   */
  readonly uploadUrl: string;
}
/**
 * Detailed information about a video
 */
export interface VideoDetailDto {
  /**
   * The id of the video
   */
  readonly id: number;
  /**
   * The title of the video
   */
  readonly title: string;
  /**
   * The description of the video
   */
  readonly description: string;
  /**
   * The URL for the playlist file
   */
  readonly playlistUrl: string;
  /**
   * The URL for the poster playlist file
   */
  readonly posterUrl: string;
  /**
   * The state of the video
   */
  readonly state: VideoState;
  /**
   * The progress of the transcoding in percent
   */
  readonly transcodeProgress: number;
  /**
   * The URLs for the poster selection
   */
  readonly posterSelectionUrls: string[];
  /**
   * The error of the video
   */
  readonly error: VideoUserError;
  readonly duration: number;
  readonly created: Date;
}
/**
 * Information about a video for the overview
 */
export class VideoDto {
  /**
   * The id of the video
   */
  readonly id: number;
  /**
   * The title of the video
   */
  readonly title: string;
  /**
   * The URL for the poster playlist file
   */
  readonly posterUrl: string;
  /**
   * The state of the video
   */
  readonly state: VideoState;
  readonly created: Date;
  readonly duration: number;
  // for vinhub selection for send
  selected?: boolean;
}
/**
 * Input for creating or updating a video
 */
export interface VideoInputDto {
  /**
   * The title of the video
   */
  readonly title: string;
  /**
   * The description of the video
   */
  readonly description: string;
}
export type VideoState = 'PENDING' | 'QUEUED' | 'TRANSCODING' | 'UPLOADING' | 'READY' | 'ERROR';
export type VideoUserError =
  | 'EMPTY_FILE'
  | 'INVALID_MEDIA_TYPE'
  | 'CORRUPT_FILE'
  | 'MISSING_VIDEO_STREAM'
  | 'MISSING_AUDIO_STREAM'
  | 'UNSUPPORTED_CODEC'
  | 'FAILED_TO_DECODE_AUDIO'
  | 'UNKNOWN_ERROR';

export class VideoListRequest {
  page: number = 0;
  page_size: number = 19;
}

export class SharedVideoRequest {
  owner_id: string;
  page: number = 0;
  page_size: number = 19;
}

export class SharedVideoResponse {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  data: SharedVideo;
}

export class SharedVideo {
  id: number;
  title: string;
  description: string;
  playlistUrl: string;
  posterUrl: string;
  duration: number;
  created: string;
}

export class VideoListResponse {
  page: number;
  pageSize: number;
  total: number;
  data: VideoDto[];
  totalPages: number;
}

export class UpdateVideoModel {
  title: string;
  description: string;
}
