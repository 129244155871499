import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { RoleGuard } from './shared/guards';

const staticRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layout/app-layout.module').then((m) => m.LayoutModule),
  },
  // TODO: add ErrorPage for handle 400/500 errors
  { path: '**', redirectTo: '' },
];

const routerConfig: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  scrollPositionRestoration: 'top',
};

@NgModule({
  imports: [RouterModule.forRoot(staticRoutes, routerConfig)],
  providers: [RoleGuard],
  exports: [RouterModule],
})
export class AppRoutesModule {}
