import { Injectable } from '@angular/core';
import { Observable, retry, Subject, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { Advisor, FileUpload, ImportedContractRequest, ImportedContractResponse } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ContractsImportService {
  public dataUploadChange = new Subject();
  public advisor: Advisor;

  constructor(private http: HttpClient) {}

  public upload(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file, file.name);

    return this.http
      .post<FileUpload>(`${environment.apiEndpoint}/b2b/contractsimporter/upload/csv`, formdata)
      .pipe(tap(() => this.dataUploadChange.next(true)));
  }

  public loadLatest(request: ImportedContractRequest): Observable<ImportedContractResponse> {
    return this.http.post<ImportedContractResponse>(
      `${environment.apiEndpoint}/b2b/contractsimporter/imported`,
      request,
    );
  }

  public deleteAll(dataSetId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2b/contractsimporter/dataset/${dataSetId}`);
  }

  public deleteContract(importedContractId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2b/contractsimporter/${importedContractId}`);
  }

  public mergeContract(importedContractId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/contractsimporter/merge/${importedContractId}`);
  }

  public downloadTemplate(type: string): Observable<any> {
    const url: string = environment.apiEndpoint + '/b2b/contractsimporter/download-template/' + type;

    const authHeader: HttpHeaders = new HttpHeaders();
    authHeader.set('Accept', 'application/pdf');

    return this.http
      .get<any>(url, {
        responseType: 'blob' as 'json',
        headers: authHeader,
      })
      .pipe(retry(1));
  }

  public importAll(): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/contractsimporter/merge/all`);
  }
}
