import { Advisor } from '../index';
import { BrokerMandateApprovalType } from '../../enum/broker-mandate-approval-type';

export class Person {
  advisor: Advisor;
  city: string;
  email: string;
  firstname: string;
  uuid: string;
  houseNumber: string;
  id: number;
  isNewCustomer: boolean;
  lastname: string;
  mobile: string;
  street: string;
  profilePictureUrl: string;
  zip: number;
  isChecked?: boolean;
  created: Date;
  isEnabled?: boolean;
  isPoolClient?: boolean;
  poolIdentification: string;
  birthday: string;
  hasPoolMandate?: boolean;
  mandateApprovalStatus?: BrokerMandateApprovalType;
}
