import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { CompanySettings } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsService {
  constructor(private http: HttpClient) {}

  public getCompanySettingsData(): Observable<CompanySettings> {
    return this.http.get<CompanySettings>(`${environment.apiEndpoint}/b2b/companysettings`);
  }

  public setRefferalInvitationFlag(flag: boolean): Observable<any> {
    return this.http.get<CompanySettings>(`${environment.apiEndpoint}/b2b/companysettings/refferal/${flag}`);
  }
}
