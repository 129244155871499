import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { RecipientsRequest, RecipientsResponse, RecipientsFilter, Client } from '../model';

@Injectable({
  providedIn: 'root',
})
export class RecipientService {
  constructor(private http: HttpClient) {}

  public recipients(recipientsRequest: RecipientsRequest): Observable<RecipientsResponse> {
    return this.http.post<RecipientsResponse>(`${environment.apiEndpoint}/b2b/recipients`, recipientsRequest);
  }

  public getRecipients(customerIds: Array<number>): Observable<Array<Client>> {
    return this.http.get<Array<Client>>(`${environment.apiEndpoint}/b2b/recipients/${customerIds}`);
  }

  public saveFilter(recipientsFilter: RecipientsFilter): Observable<any> {
    return this.http.post<RecipientsResponse>(
      `${environment.apiEndpoint}/b2b/recipients/save/filter`,
      recipientsFilter,
    );
  }

  public loadAllFilters(): Observable<Array<RecipientsFilter>> {
    return this.http.get<Array<RecipientsFilter>>(`${environment.apiEndpoint}/b2b/recipients/load/all/filter`);
  }

  public loadFilter(id: string): Observable<RecipientsFilter> {
    return this.http.get<RecipientsFilter>(`${environment.apiEndpoint}/b2b/recipients/load/filter/${id}`);
  }

  public deleteFilter(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2b/recipients/delete/filter/${id}`);
  }

  public getRecipientsAmount(id: number): Observable<any> {
    return this.http.get(`${environment.apiEndpoint}/b2b/recipients/amount/filter/${id}`);
  }
}
