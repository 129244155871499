<div class="user-sidebar-modal-container">
  <div class="modal-body p-0">
    <div class="content-header content-header-fullrow">
      <div class="content-header-section align-parent">
        <button type="button" class="btn btn-circle btn-dual-secondary align-v-r" (click)="closeFlyoutMenu()">
          <i class="bi bi-x-lg theme-color"></i>
        </button>

        <div class="content-header-item d-flex">
          <img
            class="mr-5"
            style="border-radius: 50%"
            src="{{ advisor.profilePictureUrl ?? 'https://static-assets.vinlivt.de/img/misc/fox.png' }}"
            alt="advisor avatar"
            width="30"
            height="30"
          />

          <span
            class="align-middle link-effect text-primary-dark font-w600 ellipsis user-name"
            href="be_pages_generic_profile.html"
          >
            {{ advisor?.company?.name }}
          </span>
        </div>
      </div>
    </div>

    <div class="pull-r-l mb-20">
      <div class="content-side menu-item pt-0">
        <div class="row">
          <div class="col-md-12 mb-10 text-center">Ihre Berater-ID</div>
        </div>

        <div class="row">
          <div class="col-md-12 text-center">
            @for (p of pin; track pin) {
              <span class="pin-container">{{ p }}</span>
            }

            <img
              (click)="copyPin()"
              src="https://static-assets.vinlivt.de/icons/line-icons/svg/clipboard.svg"
              alt="Kopieren"
              class="ml-5 cursor-pointer"
            />
          </div>
          <!--        <div class="col-md-2">-->
          <!--        </div>-->
        </div>
      </div>
    </div>

    @if (innerWidth < 650) {
      <div class="pull-r-l">
        <div class="block-header bg-body-light">
          <h3 class="block-title text-black">Bereiche</h3>
        </div>

        <div class="content-side menu-item">
          <a class="dropdown-item" (click)="navigateTo('analyse')">
            <img
              class="mr-2"
              src="https://static-assets.vinlivt.de/icons/line-icons/svg/chart.svg"
              width="14"
              alt="nav icon"
            />
            Analysen
          </a>

          <!--        @if (authService.isInGroup(Groups.ADVISOR) || authService.isInGroup(Groups.TRAINEE)) {-->
          <!--          <a class="dropdown-item" (click)="navigateToMediaStudio()">-->
          <!--            <img-->
          <!--              class="mr-2"-->
          <!--              src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Social/Youtube.svg"-->
          <!--              width="14"-->
          <!--              alt="nav icon"-->
          <!--            />-->
          <!--            Media Studio-->

          <!--            @if (!authService.hasRole(Roles.MEDIA_STUDIO)) {-->
          <!--              <span class="ml-15">-->
          <!--                <i class="bi bi-award"></i>-->
          <!--              </span>-->
          <!--            }-->
          <!--          </a>-->

          <!--          <a *appHasRole="[Roles.CAMPAIGN]" class="dropdown-item" (click)="navigateTo('/campaign/overview')">-->
          <!--            <img-->
          <!--              class="mr-2"-->
          <!--              src="https://static-assets.vinlivt.de/icons/line_icons_2.0/System/Radio.svg"-->
          <!--              width="14"-->
          <!--              alt="nav icon"-->
          <!--            />-->
          <!--            Kampagnen-->
          <!--          </a>-->
          <!--        }-->
        </div>
      </div>
    }

    <div class="pull-r-l">
      <div class="block-header bg-body-light">
        <h3 class="block-title text-black">Unternehmensprofil</h3>
      </div>
      <div class="content-side menu-item">
        <a class="dropdown-item" (click)="navigateTo('/settings')">
          <i class="bi bi-person-vcard mr-2"></i>
          Beraterprofil
        </a>

        <a *appHasRole="[Roles.COMPANY_ADMIN]" class="dropdown-item" (click)="navigateTo('/profile-tab')">
          <i class="bi bi-sliders mr-2"></i>
          Einstellungen
        </a>

        <a *appHasRole="[Roles.MANDATE]" class="dropdown-item" (click)="navigateTo('/mandate')">
          <i class="bi bi-ui-checks mr-2"></i>
          Maklervertrag
        </a>

        @if (authService.isInGroup(Groups.ADVISOR) || authService.isInGroup(Groups.TRAINEE)) {
          <a class="dropdown-item" (click)="navigateToPool()">
            <i class="bi bi-back mr-2"></i>
            Maklerpool

            @if (!authService.hasRole(Roles.POOL_CONNECTION)) {
              <span class="ml-15">
                <i class="bi bi-award"></i>
              </span>
            }
          </a>
        }

        <a
          *appHasRole="[Roles.COMPANY_ADMIN]"
          class="dropdown-item pointer-cursor"
          (click)="navigateTo('/subscription-manager')"
        >
          <i class="bi bi-credit-card mr-2"></i>
          Abonnements
        </a>

        <a *appHasRole="[Roles.DATA_IMPORTER]" class="dropdown-item" (click)="navigateTo('/data-advisors-table')">
          <i class="bi bi-filetype-csv mr-2"></i>
          Importer
        </a>
      </div>
    </div>

    <div class="pull-r-l">
      <div class="block-header bg-body-light">
        <h3 class="block-title text-black">Kontakt</h3>
      </div>
      <div class="content-side menu-item">
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="goToOtherExternalUrl('https://1jd89nuf213.typeform.com/to/j7WQ75Q0')"
        >
          <i class="bi bi-headset mr-2"></i>
          Support Ticket
        </a>

        <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="
            goToOtherExternalUrl(
              'https://vinlivt-data-room.notion.site/Vinlivt-Datenschutzrichtlinien-62f1101c56c748a4a573b6ac6c81c45e'
            )
          "
        >
          <i class="bi bi-shield-lock mr-2"></i>
          Datenschutzinformation
        </a>

        <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="
            goToOtherExternalUrl(
              'https://vinlivt-data-room.notion.site/Vinlivt-Nutzungsbedingungen-c9ec38b35b6a409aa5c43369eb7c8eb3'
            )
          "
        >
          <i class="bi bi-mouse3 mr-2"></i>
          Nutzungsbedingungen
        </a>

        <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="
            goToOtherExternalUrl(
              'https://vinlivt-data-room.notion.site/Vinlivt-Leistungsschein-6e738ea9483c4c96999c298a02fae8eb'
            )
          "
        >
          <i class="bi bi-book mr-2"></i>
          Leistungsschein
        </a>

        <!-- <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="goToOtherExternalUrl('https://www.vinlivt.de/kontakt/')"
        >
           Beratung
        </a>
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="goToOtherExternalUrl('https://www.vinlivt.de/ueber-uns/')"
        >
          Team
        </a>
        <a
          class="dropdown-item"
          href="javascript:void(0)"
          (click)="goToOtherExternalUrl('https://www.vinlivt.de/datenschutz/')"
        >
          Datenschutz
        </a>
        <a class="dropdown-item" href="javascript:void(0)" (click)="goToOtherExternalUrl('https://www.vinlivt.de/agb/')">
          AGB
        </a> -->
      </div>
    </div>

    <div class="content-side">
      <div class="block pull-t pull-r-l">
        <div class="block-content block-content-full block-content-sm bg-white">
          <button type="button" class="btn btn-block btn-rounded btn-outline-secondary" (click)="logout()">
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
