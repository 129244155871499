import { BudgetCategory } from '../budget-category';

export class BudgetCategoryResponse {

    budgetCategoryList: Array<BudgetCategory> = new Array<BudgetCategory>();
    totalExpenses: number = 0;
    incomes: number = 0;
    diff: number = 0;
    possibleCredit: number = 0;
}
