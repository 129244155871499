import { Person } from './auth/person';
import { AttachmentContractTypeEnum } from '../enum/attachment-contract-type';

export class Attachment {
  id: number;
  originalFilename: string;
  fileName: string;
  contentType: string;
  extension: string;
  downloadUrl: string;
  assetUrl: string;
  title: string;
  token: string;
  size: string;
  image: boolean;
  description: string;
  uploadedByDisplayname: string;
  uploadedBy: Person;
  created: Date = new Date();
  rejected: boolean;
  attachmentRejectionReasonEnum: string;
  uploadedByUserType: string;
  attachmentContractType: string;
  attachmentContractTypeEnum: AttachmentContractTypeEnum;
}
