import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe, NgClass, NgOptimizedImage } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, tap } from 'rxjs';

import { MediaPreviewDesktopModalComponent } from '../media-preview-desktop-modal/media-preview-desktop-modal.component';
import { MediaPreviewMobileModalComponent } from '../media-preview-mobile-modal/media-preview-mobile-modal.component';

import { BrokerMandateApprovalService, BrokerMandateService } from '../../service';

import { BrokerMandateApprovalModel, ClientDetails, ErrorResponse } from '../../model';
import { MediaPreviewBaseModalComponent } from '../media-preview-base-modal/media-preview-base-modal.component';
import { ResetMandateStatusModalComponent } from '../reset-mandate-status-modal/reset-mandate-status-modal.component';
import * as moment from 'moment';
import { MandateUpdateService } from '../../global-store';

class PdfDocument {
  public readonly url: string;

  public constructor(private readonly blob: Blob) {
    this.url = URL.createObjectURL(blob);
  }

  public destroy(): void {
    URL.revokeObjectURL(this.url);
  }
}

type PdfType = 'MANDATE' | 'POWER_OF_ATTORNEY' | 'PRIVACY_CONSENT';
const pdfTypes: PdfType[] = ['MANDATE', 'POWER_OF_ATTORNEY', 'PRIVACY_CONSENT'];

@Component({
  standalone: true,
  selector: 'app-verify-mandate-modal',
  templateUrl: './verify-mandate-modal.component.html',
  styleUrls: ['./verify-mandate-modal.component.scss'],
  imports: [NgOptimizedImage, RouterModule, DatePipe, NgClass],
})
export class VerifyMandateModalComponent {
  @Input() brokerMandateApprovalModel: BrokerMandateApprovalModel;
  @Input() userDetailsModalActive: boolean = false;
  @Input() user: ClientDetails;

  @Output() reloadTrigger = new EventEmitter<boolean>();

  protected showSpinner: boolean = false;
  protected isSynching: boolean = false;
  protected resetLoading: boolean = false;
  protected status: string = null;
  public modalRef: BsModalRef;

  private readonly documents: Partial<Record<PdfType, PdfDocument>> = {};

  constructor(
    private brokerMandateApprovalService: BrokerMandateApprovalService,
    private mandateUpdateService: MandateUpdateService,
    private brokerMandateService: BrokerMandateService,
    private toasterService: ToastrService,
    private bsModalService: BsModalService,
    protected router: Router,
  ) {}

  protected openMewBrokerMandateModal(template: TemplateRef<any>): void {
    forkJoin(
      pdfTypes.map((type: PdfType) =>
        (this.brokerMandateApprovalModel
          ? this.brokerMandateService.downloadMandate(type, this.brokerMandateApprovalModel.id)
          : this.brokerMandateService.pdf(type)
        ).pipe(tap((blob: Blob) => (this.documents[type] = new PdfDocument(blob)))),
      ),
    ).subscribe();

    this.modalRef = this.bsModalService.show(template);
  }

  protected setStatus(decision: string): void {
    this.status = decision;
  }

  protected downloadPdf(type: string): void {
    let typeName: string;
    switch (type) {
      case 'MANDATE':
        typeName = 'Maklermandat.pdf';
        break;
      case 'POWER_OF_ATTORNEY':
        typeName = 'Maklervollmacht.pdf';
        break;
      case 'PRIVACY_CONSENT':
        typeName = 'Datenschutzeinwilligung.pdf';
        break;
    }

    let d = moment(this.brokerMandateApprovalModel.created).format('YYYY_MM_DD');
    let filename = d + '_' + this.user?.firstname + '_' + this.user?.lastname + '_' + typeName;

    const anchor = document.createElement('a');
    anchor.href = this.documents[type].url;
    anchor.download = filename;
    document.body.append(anchor);
    anchor.click();
    anchor.remove();
  }

  protected preview(type: PdfType): void {
    const pdfDocument: PdfDocument = this.documents[type];
    if (!pdfDocument) {
      return;
    }

    const mediaPreviewModalRef = this.bsModalService.show<MediaPreviewBaseModalComponent>(
      window.innerWidth > 650 ? MediaPreviewDesktopModalComponent : MediaPreviewMobileModalComponent,
      {},
    );

    (mediaPreviewModalRef.content as MediaPreviewMobileModalComponent).size = null;

    const modal = mediaPreviewModalRef.content;
    modal.downloadUrl = pdfDocument.url;
    modal.filename = 'Maklermandat.pdf';
    modal.type = 'PDF';
  }

  protected saveDecision(): void {
    this.showSpinner = true;

    let req = this.brokerMandateApprovalModel;
    req.status = this.status;

    this.brokerMandateApprovalService.setStatus(req).subscribe({
      next: (): void => {
        this.brokerMandateApprovalModel.status = this.status;
        this.mandateUpdateService.setUpdatedMandate(true);

        if (this.status === 'APPROVED') {
          this.toasterService.info('Mandat wurde akzeptiert.');
        } else {
          this.toasterService.info('Mandat erfolgreich gespeichert!');
        }

        setTimeout((): void => {
          this.reloadTrigger.emit(true);
          this.showSpinner = false;
          this.modalRef.hide();
        }, 1000);
      },
      error: (error): void => {
        const serverError: ErrorResponse = error;
      },
    });
  }

  protected resetMandateStatus(value: boolean): void {
    // Value can be true or false.False if we deprecate mandate true if we accept it.
    this.resetLoading = true;

    this.brokerMandateApprovalService.resetMandateStatus(this.user.id, value).subscribe({
      next: (): void => {
        this.resetLoading = false;
        this.reloadTrigger.emit();
        this.modalRef.hide();
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected showResetStatusModal(): void {
    const resetMandateStatusModalRef = this.bsModalService.show(ResetMandateStatusModalComponent, {});

    resetMandateStatusModalRef.content.setApprovedStatus.subscribe((value: boolean): void => {
      if (value) {
        this.resetMandateStatus(value);
      }

      resetMandateStatusModalRef.hide();
    });
  }

  protected setAcknoledged(): void {
    this.brokerMandateApprovalModel.status = 'REVOCATION_CONFIRMED';
    this.closeModal();

    this.brokerMandateApprovalService.setStatus(this.brokerMandateApprovalModel).subscribe({
      next: (): void => {
        this.modalRef.hide();
        this.reloadTrigger.emit(true);
        this.toasterService.info('Widerruf zur Kenntniss genommen!');
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected closeModal(): void {
    this.modalRef.hide();
    this.status = null;
  }

  protected synchroniseMandate(): void {
    this.isSynching = true;
    this.brokerMandateApprovalService.synchorinseMandate(this.user?.id).subscribe({
      next: (): void => {
        console.log('on next');
      },
      error: (error): void => {
        console.log(error);
        this.isSynching = false;
      },
      complete: (): void => {
        this.isSynching = false;
      },
    });
  }
}
