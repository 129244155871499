import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InsuranceCategory, InsuranceProvider } from '../model';
import { Observable, retry } from 'rxjs';

import { environment } from '../../../environments/environment';

import { InsuranceTypeEnum } from '../enum';

@Injectable({
  providedIn: 'root',
})
export class InsuranceService {
  constructor(private http: HttpClient) {}

  public categories(): Observable<Array<InsuranceCategory>> {
    return this.http.get<Array<InsuranceCategory>>(`${environment.apiEndpoint}/b2b/insurances`);
  }

  public providers(insuranceTypeEnums: Array<InsuranceTypeEnum>): Observable<Array<InsuranceProvider>> {
    return this.http.get<Array<InsuranceProvider>>(
      `${environment.apiEndpoint}/b2b/insurances/providers?types=${insuranceTypeEnums}`,
    );
  }

  public downloadInsuranceMapping(): Observable<any> {
    const url: string = environment.apiEndpoint + '/b2b/insurances/provider-mapping';

    const authHeader: HttpHeaders = new HttpHeaders();
    authHeader.set('Accept', 'application/json');

    return this.http
      .get<any>(url, {
        responseType: 'blob' as 'json',
        headers: authHeader,
      })
      .pipe(retry(1));
  }

  public downloadInsuranceProviderCsv(): Observable<any> {
    const url: string = environment.apiEndpoint + '/b2b/insurances/csv-download';

    const authHeader = new HttpHeaders();
    authHeader.set('Accept', 'text/csv');

    return this.http
      .get<any>(url, {
        responseType: 'text' as 'json',
        headers: authHeader,
      })
      .pipe(retry(1));
  }

  public downloadInsuranceGroupsCsv(): Observable<any> {
    const url: string = environment.apiEndpoint + '/b2b/insurancegroup/csv-download';

    const authHeader: HttpHeaders = new HttpHeaders();
    authHeader.set('Accept', 'text/csv');

    return this.http
      .get<any>(url, {
        responseType: 'text' as 'json',
        headers: authHeader,
      })
      .pipe(retry(1));
  }
}
