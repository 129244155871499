// Angular default imports
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';

// Bootstrap imports
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgxSliderModule } from 'ngx-slider-v2';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { defineLocale, deLocale } from 'ngx-bootstrap/chronos';
import { ToastrModule } from 'ngx-toastr';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxEditorModule } from 'ngx-editor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PdfViewerModule } from 'ng2-pdf-viewer';

// Chart libs
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

// env & custom libs
import { environment } from '../environments/environment';
import { TOKEN_PROVIDER, WEBSOCKET_ENDPOINT } from '@vinlivt/websocket';

// modules
import { AppRoutesModule } from './app-routing.module';
import { LayoutModule } from './layout/app-layout.module';
import { AUTH_SERVICE_TOKEN } from './shared/service';
import { SharedModule } from './shared/shared.module';

// components
import { AppComponent } from './app.component';

registerLocaleData(localeDe);
defineLocale('de-de', deLocale);
require('moment/locale/de'); // Set the locale to German

Chart.register(annotationPlugin, ...registerables);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutesModule,
    LayoutModule,
    SharedModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    BsDatepickerModule,
    HttpClientModule,
    NgProgressHttpModule,
    NgxSliderModule,
    AccordionModule,
    TabsModule,
    ImageCropperModule,
    NgxSpinnerModule,
    NgxEditorModule,
    NgxSkeletonLoaderModule,
    NgxMaskDirective,
    PdfViewerModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right',
    }),
    NgProgressModule.withConfig({
      spinnerPosition: 'left',
      thick: true,
      color: '#802a9e',
      spinner: false,
    }),
  ],
  exports: [],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'de-DE',
    },
    {
      provide: TOKEN_PROVIDER,
      useExisting: AUTH_SERVICE_TOKEN,
    },
    {
      provide: WEBSOCKET_ENDPOINT,
      useValue: environment.webSocketEndpoint,
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
