import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { PoolConnection, Pool, CustomerMatchingReport } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PoolService {
  public poolDataChangedSubject = new Subject();

  constructor(private http: HttpClient) {}

  public loadPools(): Observable<Pool[]> {
    return this.http.get<Pool[]>(`${environment.apiEndpoint}/pool/pools`);
  }

  public compareCustomers(): Observable<CustomerMatchingReport> {
    return this.http.get<CustomerMatchingReport>(`${environment.apiEndpoint}/b2b/netfonds/compare`);
  }

  public synchCustomer(clientId: number): Observable<any> {
    return this.http.get<CustomerMatchingReport>(`${environment.apiEndpoint}/b2b/netfonds/synch/person/${clientId}`);
  }

  public importCleanCustomers(importList: Array<number>): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/netfonds/import/${importList}`);
  }

  public importCustomers(): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2b/netfonds/import`);
  }

  public loadPoolConnection(keycloakId: string): Observable<PoolConnection> {
    return this.http.get<PoolConnection>(`${environment.apiEndpoint}/pool/poolconnection/advisor/${keycloakId}`);
  }

  public testConnection(poolConnection: PoolConnection): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/pool/poolconnection/test`, poolConnection);
  }

  public isConnected(keycloakId: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiEndpoint}/pool/poolconnection/ping/${keycloakId}`);
  }

  public save(poolConnection: PoolConnection): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/pool/poolconnection`, poolConnection);
  }

  public deletePoolConnection(): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/pool/poolconnection`);
  }

  public toggleActivationOfPool(id: number): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/pool/poolconnection/toggle/status/${id}`);
  }

  public createCustomer(clientId: string): Observable<CustomerMatchingReport> {
    return this.http
      .get<CustomerMatchingReport>(`${environment.apiEndpoint}/b2b/netfonds/create/person/${clientId}`)
      .pipe(tap(() => this.poolDataChangedSubject.next(true)));
  }
}
