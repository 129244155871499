import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { WebSocketService } from '@vinlivt/websocket';

import { environment } from '../../../environments/environment';

import { AdvisorsService, AUTH_SERVICE_TOKEN, AuthService, VinhubService } from '../../shared/service';

import { Advisor, Company, VinhubMessage } from '../../shared/model';

import { Roles } from '../../shared/enum/roles.enum';

import WithSubscription from '../../shared/utils/WithSubscription';

@Component({
  selector: 'app-mobile-bottom-navigation',
  templateUrl: './mobile-bottom-navigation.component.html',
  styleUrls: ['./mobile-bottom-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileBottomNavigationComponent extends WithSubscription(Object) implements OnInit {
  @Output() open = new EventEmitter<any>();

  protected devMode: boolean = false;
  protected isFlutter: boolean = false;
  protected innerWidth: number = window.innerWidth;

  protected advisor: Advisor = new Advisor();
  protected company: Company = new Company();

  protected unreadMessages: number;

  constructor(
    protected readonly router: Router,
    private readonly advisorsService: AdvisorsService,
    private readonly webSocketService: WebSocketService,
    private readonly vinhubService: VinhubService,
    @Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
  ) {
    super();
    this.isFlutter = this.detectFlutter();

    // Listen for messages from WebView
    window.addEventListener('message', this.handleMessage.bind(this));
  }

  public ngOnInit(): void {
    this.listenForNewMessages();
    this.loadAdvisorAccount();
    this.loadStatistics();
  }

  protected detectFlutter(): boolean {
    const agent: string = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('flutter') > -1:
        return true;
      default:
        return false;
    }
  }

  private loadStatistics(): void {
    this.vinhubService.checkUnreadMessages().subscribe({
      next: (data: number): void => {
        this.unreadMessages = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private listenForNewMessages(): void {
    this.listenToSubject(
      this.webSocketService.subscribe<number>('chat/changed-unread-count'),
      (messageCount: number): void => {
        this.unreadMessages = messageCount;
      },
    );

    this.listenToSubject(
      this.webSocketService.subscribe<VinhubMessage>('chat/new-message'),
      (message: VinhubMessage): void => {
        if (message.authorId !== this.authService.userId) {
          this.unreadMessages++;
          this.messageArrivedPlaySound();
        }
      },
    );
  }

  private messageArrivedPlaySound(): void {
    const audio: HTMLAudioElement = new Audio();
    audio.src = 'assets/sounds/message-ring.mp3';
    audio.load();
    audio.play();
  }

  private loadAdvisorAccount(): void {
    this.advisorsService.getAdvisorProfile().subscribe({
      next: (advisor: Advisor): void => {
        this.advisor = advisor;
        this.getInfoAfterGettingAdvisor();
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private getInfoAfterGettingAdvisor(): void {
    this.company = this.advisor.company;

    // turn on mdia studio only for dev mode and vinlivt user account on prod
    if (!environment.production || (environment.production && this.company.id === 2)) {
      this.devMode = true;
    }
  }

  private handleMessage(event: MessageEvent): void {
    // Handle messages received from Flutter WebView
    // You can define a specific message format to identify the source
    // For example, check event.data to contain a specific identifier.
    // console.log('Received message from WebView:', event.data);
  }

  protected readonly Roles = Roles;
}
