import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Notes } from '../model';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  noteSubject = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  public postNotes(body): Observable<any> {
    return this.http.post(`${environment.apiEndpoint}/b2b/notes`, body);
  }

  public getNotesList(customerId): Observable<Notes[]> {
    return this.http.get<Notes[]>(`${environment.apiEndpoint}/b2b/notes/list/${customerId}`);
  }

  public deleteNotes(id): Observable<any> {
    return this.http.delete(`${environment.apiEndpoint}/b2b/notes/${id}`);
  }
}
