import { Campaign } from './campaign';

export class CampaignDashboard {
  lastCampaignId: number;
  lastCampaignTitle: string;
  lastCampaigns: Campaign[] = [];
  lastRecipientsFilters: LastRecipientsFilter[] = [];
  openingStatistics: OpeningStatisticsPoints[] = [];
  totalRecipients: number = 0;
  totalReadMessages: number = 0;
}

export class OpeningStatisticsPoints {
  label: string;
  dayOfWeek: string;
  value: number;
}

export class LastRecipientsFilter {
  id: number;
  created: string;
  name: string;
  recipientsAmount: number;
}
