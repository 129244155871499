import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DamageReportService {
  constructor(private http: HttpClient) {}

  public getDamageReport(contractUid: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/damages/contract/${contractUid}`);
  }
}
