import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastFive',
})
export class LastFivePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value ? '...' + value.slice(-5) : value;
    } else {
      return '-';
    }
  }
}
