export class PaymentInvoicesOverviewModel {}

export class GroupedAdvisorsToAssignedProductsModel {
  productId: string;
  productName: string;
  advisors: string[];
}

export class ProductsSummaryModel {
  advisorUuid: string;
  products: CommonProductsSummaryModel[];
}

export class ProductsSummaryRequestBody {
  productTypes: string[];
  advisorUuid?: string;
}

export class CommonProductsSummaryModel {
  productName: string;
  price: number;
  subscriptionPeriod: string;
  productId: string;
}

export class ProductsModel {
  productName: string;
  productId: string;
}

export class CustomerProductsModel {
  name: string;
  features: string[];
}

export class CustomerProductsOverviewModel {
  productId: string;
  productType: string;
  productName: string;
  totalSeats: number;
  seatsInUse: number;
  subtotal: number;
  selected?: boolean;
  disabled?: boolean;
}

export class SubscriptionModel {
  id: string;
  subscriptionProducts: SubscriptionProductModel[];
}

export class SubscriptionProductModel {
  productId: string;
  productName: string;
  price: number;
  type: string;
  description: string;
  features: string[];
  selected?: boolean;
  disabled?: boolean;
}

export class StripeInvoiceResponseModel {
  invoices: StripeInvoiceModel[];
  hasMore: boolean;
}

export class StripeInvoiceModel {
  id: string;
  creationDate: Date;
  totalAmount: number;
  invoicePdf: string;
}

export class StripeInvoiceByCriteriaRequestModel {
  customerId: string;
  criteria: StripeInvoiceCriteriaModel[];
  perPage: number;
  page: string;
}

export class StripeInvoiceCriteriaModel {
  field: string;
  operation: string;
  value: string;
}

export class StripeInvoiceByCriteriaResponseModel {
  invoices: StripeInvoiceModel[];
  nextPage: string;
}

export class SubscriptionPermissionsModel {
  companyId: string;
  userId: string;
  productIds: string[];
}

export class PaymentRequestModel {
  products: PaymentRequestProductsModel[];
  customerId: string;
  metadata: any;
}

export class PaymentRequestProductsModel {
  id: string;
  quantity?: number;
}

export class StripeSubscriptionCreationRequest {
  products: PaymentRequestProductsModel[];
  customerId: string;
  metadata: any;
  period: string;
  advisorKeycloakId: string;
}

export class SubscriptionPreviewRequestModel {
  customerId: string;
  subscriptionId?: string;
  coupon?: string;
  productList?: PaymentRequestProductsModel[];
}

export class SubscriptionPreviewResponseModel {
  selectedProducts: SubscriptionProductModel[];
  couponDiscount: number;
  netAmount: number;
  taxAmount: number;
  totalAmount: number;
}

export class PaymentMethodModel {
  paymentMethodId: string;
  brand: string;
  paymentType: string;
  lastFourDigits: string;
  isDefaultPaymentMethod: boolean;
  expireAt: Date;
}

export class SubscriptionUpdateRequestModel {
  customerId: string;
  advisorId: string;
  subscriptionProducts: PaymentRequestProductsModel[];
}

export class SubscriptionCreationSteppers {
  step: number;
  title: string;
  description: string;
  finished: boolean;
}

export class CompanyStripeModel {
  stripeCustomerId: string;
  name: string;
  email: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
}

export class SubscriptionInfoModel {
  id: string;
  subscriptionProducts: SubscriptionProductModel[];
}
