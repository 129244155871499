export class ClientsRequestShortInfo {
  page: number = 0;
  pageSize: number = 25;
  sortField: string = 'firstName';
  ascendingSorting: boolean = false;
  query: string;
}

export class ClientsResponseShortInfo {
  page: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  totalItems: number;
  clients: ClientShortInfo[];
}

export class ClientShortInfo {
  id: number;
  firstname: string;
  lastname: string;
  profilePictureUrl: string;
  uuid: string;
  advisorUuid: string;
}
