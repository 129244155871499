<div class="mobile-bottom-navigation-container" [ngClass]="{ 'flutter-layout': isFlutter }">
  <ul class="nav-main">
    <li>
      <a class="p-0 bottom-nav-link d-flex flex-column" routerLinkActive="active" routerLink="/dashboard">
        <img
          src="https://static-assets.vinlivt.de/icons/line-icons/svg/home_gray.svg"
          class="nav-icon"
          [ngClass]="{ 'nav-icon-active': router.url.includes('/dashboard') }"
          alt="nav icon"
        />

        <span class="nav-text text-12" [ngClass]="{ 'nav-text-active': router.url.includes('/dashboard') }">
          Übersicht
        </span>
      </a>
    </li>

    <li>
      <a
        class="p-0 bottom-nav-link d-flex flex-column"
        routerLinkActive="active"
        routerLink="/activity"
        data-toggle="layout"
        data-action="layout"
      >
        <img
          src="https://static-assets.vinlivt.de/icons/line-icons/svg/alarm-gray.svg"
          class="nav-icon"
          [ngClass]="{ 'nav-icon-active': router.url.includes('/activity') }"
          alt="nav icon"
        />

        <span class="nav-text text-12" [ngClass]="{ 'nav-text-active': router.url.includes('/activity') }">
          Updates
        </span>
      </a>
    </li>

    <li>
      <a
        class="p-0 bottom-nav-link d-flex flex-column"
        routerLinkActive="active"
        routerLink="/clients"
        data-toggle="layout"
        data-action="layout"
      >
        <img
          src="https://static-assets.vinlivt.de/icons/line-icons/svg/users_gray.svg"
          class="nav-icon"
          [ngClass]="{ 'nav-icon-active': router.url.includes('/clients') }"
          alt="nav icon"
        />

        <span class="nav-text text-12" [ngClass]="{ 'nav-text-active': router.url.includes('/clients') }">
          Kunden
        </span>
      </a>
    </li>

    <li *appHasRole="[Roles.CHAT]">
      <a
        class="p-0 bottom-nav-link d-flex flex-column"
        routerLinkActive="active"
        routerLink="/news"
        data-toggle="layout"
      >
        <img
          src="https://static-assets.vinlivt.de/icons/line-icons/svg/chats-gray.svg"
          class="nav-icon"
          [ngClass]="{ 'nav-icon-active': router.url.includes('/news') }"
          alt="nav icon"
        />

        @if (unreadMessages > 0) {
          <div class="unread-messages-bubble"></div>
        }

        <span class="nav-text text-12" [ngClass]="{ 'nav-text-active': router.url.includes('/news') }"> Chats </span>
      </a>
    </li>

    <!--    <li>-->
    <!--      <a-->
    <!--        class="p-0 bottom-nav-link d-flex flex-column"-->
    <!--        routerLinkActive="active"-->
    <!--        routerLink="/calendar"-->
    <!--        data-toggle="layout"-->
    <!--      >-->
    <!--        <img-->
    <!--          src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Basic/Calendar_Days.svg"-->
    <!--          class="nav-icon"-->
    <!--          [ngClass]="{ 'nav-icon-active': router.url.includes('/calendar') }"-->
    <!--          alt="nav icon"-->
    <!--        />-->

    <!--        <span class="nav-text text-12" [ngClass]="{ 'nav-text-active': router.url.includes('/calendar') }">-->
    <!--          Kalender-->
    <!--        </span>-->
    <!--      </a>-->
    <!--    </li>-->

    <!--      <li>-->
    <!--        <a-->
    <!--          class="p-0 bottom-nav-link d-flex flex-column"-->
    <!--          routerLinkActive="active"-->
    <!--          routerLink="/media-studio"-->
    <!--          data-toggle="layout"-->
    <!--        >-->
    <!--          <img-->
    <!--            src="https://static-assets.vinlivt.de/icons/line_icons_2.0/Social/Youtube.svg"-->
    <!--            class="nav-icon"-->
    <!--            [ngClass]="{ 'nav-icon-active': router.url.includes('/media-studio') }"-->
    <!--            alt="nav icon"-->
    <!--          />-->

    <!--          &lt;!&ndash;        <span class="nav-text text-12" [ngClass]="{ 'nav-text-active': router.url.includes('/media-studio') }">&ndash;&gt;-->
    <!--          &lt;!&ndash;          Mediastudio&ndash;&gt;-->
    <!--          &lt;!&ndash;        </span>&ndash;&gt;-->
    <!--        </a>-->
    <!--      </li>-->

    <!--      <li>-->
    <!--        <a-->
    <!--          class="p-0 bottom-nav-link d-flex flex-column"-->
    <!--          routerLinkActive="active"-->
    <!--          routerLink="/campaign/overview"-->
    <!--          data-toggle="layout"-->
    <!--        >-->
    <!--          <img-->
    <!--            src="https://static-assets.vinlivt.de/icons/line_icons_2.0/System/Radio.svg"-->
    <!--            class="nav-icon"-->
    <!--            [ngClass]="{ 'nav-icon-active': router.url.includes('/campaign/overview') }"-->
    <!--            alt="nav icon"-->
    <!--          />-->

    <!--          &lt;!&ndash;        <span class="nav-text text-12" [ngClass]="{ 'nav-text-active': router.url.includes('/campaign/overview') }">&ndash;&gt;-->
    <!--          &lt;!&ndash;          Kampagnen&ndash;&gt;-->
    <!--          &lt;!&ndash;        </span>&ndash;&gt;-->
    <!--        </a>-->
    <!--      </li>-->
  </ul>
</div>
