import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Advisor, Company } from '../model';

@Injectable({
  providedIn: 'root',
})
export class AdvisorInfoUpdateService {
  private advisor: BehaviorSubject<Advisor> = new BehaviorSubject<Advisor>(new Advisor());
  private logo: BehaviorSubject<Company> = new BehaviorSubject<Company>(new Company());

  constructor() {}

  public getUpdatedAdvisor(): Observable<Advisor> {
    return this.advisor.asObservable();
  }

  public setUpdatedAdvisor(value: Advisor): void {
    this.advisor.next(value);
  }

  public getUpdatedCompanyLogo(): Observable<Company> {
    return this.logo.asObservable();
  }

  public setUpdatedLogo(value: Company): void {
    this.logo.next(value);
  }
}
