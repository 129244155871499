import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AUTH_SERVICE_TOKEN, AuthService } from '../service';

@Injectable()
export class RoleGuard {
  constructor(@Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const requiredRoles: string[] = route.data['roles'] as string[];
    return this.authService.hasAllNeededRoles(requiredRoles);
  }
}
