import { InsuranceGroupTypeEnum } from '../enum/insurance-group-type.enum';
import { InsuranceTypeEnum } from '../enum/insurance-type.enum';

export class InsuranceCategory {
  name: string;
  insuranceGroups: Array<InsuranceGroup>;
}

export class InsuranceGroup {
  id: number;
  name: string;
  checked?: boolean;
  insuranceGroupType: InsuranceGroupTypeEnum;
  insuranceTypes: Array<InsuranceTypeEnum>;
}

export class InsuranceProvider {
  id: number;
  name: string;
  checked?: boolean;
  displayName: string;
  spriteCoordinate: any;
}
