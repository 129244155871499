import { ChatMessage } from '../contractsmanager/chat-message';

export class ChatMessageResponse {

    offset: number;
    limit: number;
    totalElements: number;
    totalPages: number;
    messages: Array<ChatMessage> = new Array<ChatMessage>();
}
