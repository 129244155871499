import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export type MediaType = 'IMAGE' | 'PDF' | 'OTHER';

@Component({
  standalone: true,
  selector: 'app-media-preview-base-modal-container',
  encapsulation: ViewEncapsulation.None,
  template: '',
})
export abstract class MediaPreviewBaseModalComponent implements OnDestroy {
  public filename: string;
  public size: number;

  protected blobUrl: string | null = null;
  protected fileBytes: Uint8Array | null = null;
  protected downloadInProgress = false;
  protected pdfLoaded = false;

  private shouldRevokeBlobUrl = false;

  protected constructor(public readonly modalRef: BsModalRef) {}

  public _type: MediaType;

  public get type(): MediaType {
    return this._type;
  }

  public set type(value: MediaType) {
    this._type = value;

    this.cleanBlobUrl();
    this.downloadFileIfNecessary();
  }

  private _downloadUrl: string;

  public get downloadUrl(): string {
    return this._downloadUrl;
  }

  public set downloadUrl(value: string) {
    this._downloadUrl = value;

    this.cleanBlobUrl();
    this.downloadFileIfNecessary();
  }

  protected get sizeFormatted(): string {
    if (this.size < 1024) {
      return `${this.size} B`;
    } else if (this.size < 1024 * 1024) {
      return `${(this.size / 1024).toFixed(2)} KB`;
    } else {
      return `${(this.size / (1024 * 1024)).toFixed(2)} MB`;
    }
  }

  public static getTypeFromExtension(extension: string): MediaType {
    switch (extension) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'webp':
      case 'heic':
        return 'IMAGE';
      case 'pdf':
        return 'PDF';
      default:
        return 'OTHER';
    }
  }

  public ngOnDestroy(): void {
    this.cleanBlobUrl();
  }

  protected async download(): Promise<void> {
    await this.downloadFile();

    const anchor = document.createElement('a');
    anchor.href = this.blobUrl;
    anchor.download = this.filename;
    anchor.click();
  }

  private async downloadFileIfNecessary(): Promise<void> {
    if (this.type === 'IMAGE' || this.type === 'PDF') {
      await this.downloadFile();
    }
  }

  private async downloadFile(): Promise<void> {
    if (this.downloadInProgress || this.blobUrl) {
      return;
    }

    this.downloadInProgress = true;
    try {
      if (this.downloadUrl.startsWith('blob:')) {
        this.blobUrl = this.downloadUrl;
        this.shouldRevokeBlobUrl = false;
        this.fileBytes = new Uint8Array(await (await fetch(this.downloadUrl)).arrayBuffer());
      } else {
        const response = await fetch(this.downloadUrl);
        const blob = await response.blob();

        this.blobUrl = URL.createObjectURL(blob);
        this.shouldRevokeBlobUrl = true;
        this.fileBytes = new Uint8Array(await blob.arrayBuffer());
      }
    } finally {
      this.downloadInProgress = false;
    }
  }

  private cleanBlobUrl(): void {
    if (this.blobUrl) {
      if (this.shouldRevokeBlobUrl) {
        URL.revokeObjectURL(this.blobUrl);
      }
      this.blobUrl = null;
    }
  }
}
