import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { CompanyConsentResponse, ConsentContent } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ConsentDataService {
  constructor(private http: HttpClient) {}

  public getConsents(): Observable<CompanyConsentResponse> {
    return this.http.get<CompanyConsentResponse>(`${environment.apiEndpoint}/b2b/consent/company`);
  }

  public saveConsent(consentContent: ConsentContent): any {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/consent`, consentContent);
  }
}
