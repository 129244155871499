import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NetfondsClientsService {
  constructor(private http: HttpClient) {}

  public all(unmappedOnes: boolean) {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/netfonds/customers?unmapped=${unmappedOnes}`);
  }

  public import(poolIdentification: string) {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/netfonds/customers/import/${poolIdentification}`);
  }

  public exportMandate(id: number) {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/netfonds/customers/export/${id}`);
  }
}
