import { Injectable } from '@angular/core';
import { Observable, retry, Subject, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import {
  Advisor,
  ClientsImportData,
  FileUpload,
  ImportedClientResponse,
  ImportedClientRequest,
  ImportedClient,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class ClientsImportService {
  public dataUploadChange = new Subject();
  advisor: Advisor;

  constructor(private http: HttpClient) {}

  public upload(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file, file.name);

    return this.http
      .post<FileUpload>(`${environment.apiEndpoint}/b2b/clientsimporter/upload/csv`, formdata)
      .pipe(tap((): void => this.dataUploadChange.next(true)));
  }

  public loadLatest(request: ImportedClientRequest): Observable<ImportedClientResponse> {
    return this.http.post<ImportedClientResponse>(`${environment.apiEndpoint}/b2b/clientsimporter/imported`, request);
  }

  public deleteClient(importedClientId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2b/clientsimporter/${importedClientId}`);
  }

  public deleteAll(dataSetId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2b/clientsimporter/dataset/${dataSetId}`);
  }

  public import(clientsImportData: ClientsImportData): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/clientsimporter/import`, clientsImportData);
  }

  public merge(clientsImportData: ClientsImportData): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/clientsimporter/merge`, clientsImportData);
  }

  public updateClient(client: ImportedClient) {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/clientsimporter`, client);
  }

  public downloadTemplate(type: string): Observable<any> {
    const url: string = environment.apiEndpoint + '/b2b/clientsimporter/download-template/' + type;

    const authHeader: HttpHeaders = new HttpHeaders();
    authHeader.set('Accept', 'application/pdf');

    return this.http
      .get<any>(url, {
        responseType: 'blob' as 'json',
        headers: authHeader,
      })
      .pipe(retry(1));
  }
}
