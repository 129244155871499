import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, tap } from 'rxjs';

import { environment } from '../../../environments/environment';

import { FileUpload } from '../model';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  public profileDataChangedSubject = new Subject();

  constructor(private http: HttpClient) {}

  public upload(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file, file.name);

    return this.http
      .post<FileUpload>(`${environment.apiEndpoint}/b2b/advisor/upload/profilepicture`, formdata)
      .pipe(tap(() => this.profileDataChangedSubject.next(true)));
  }

  public deleteProfilePic(): Observable<void> {
    return this.http
      .delete<void>(`${environment.apiEndpoint}/b2b/advisor/profilepicture`)
      .pipe(tap(() => this.profileDataChangedSubject.next(true)));
  }
}
