<div class="reset-mandate-status-modal-container">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="bsModalRef.hide()">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <img
          ngSrc="https://static-assets.vinlivt.de/icons/premium/clay/320/Folder%202.png"
          width="75"
          height="75"
          alt="folder"
        />
      </div>

      <div class="col-12 text-center my-15">
        <div class="section-title">Hat der Kunde bereits ein Mandat?</div>
      </div>

      <div class="col-6">
        <button class="btn btn-block orange-btn btn-rounded btn-noborder" (click)="setApprovedStatus.emit(false)">
          NEIN
        </button>
      </div>

      <div class="col-6">
        <button class="btn btn-block green-btn btn-rounded btn-noborder" (click)="setApprovedStatus.emit(true)">
          JA
        </button>
      </div>
    </div>
  </div>
</div>
