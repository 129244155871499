import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { CommunicationContractResponse, ContractsRequest, VinhubChat } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ContractsHubService {
  constructor(private http: HttpClient) {}

  public loadContracts(contractsRequest: ContractsRequest): Observable<CommunicationContractResponse> {
    return this.http.post<CommunicationContractResponse>(
      `${environment.apiEndpoint}/b2b/communication/contract/list`,
      contractsRequest,
    );
  }

  public startConversation(uid: string = null, uuid: string): Observable<VinhubChat> {
    let url: string = '';

    if (uid) {
      url = `${environment?.apiEndpoint}/b2b/contractsmessage/start?uid=${uid}&userId=${uuid}`;
    } else {
      url = `${environment?.apiEndpoint}/b2b/contractsmessage/start?userId=${uuid}`;
    }

    return this.http.get<VinhubChat>(`${url}`);
  }
}
