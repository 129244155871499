import { Advisor } from '../advisor';

export class Client {
  id: number;
  uuid: string;
  firstname: string;
  lastname: string;
  profilePictureUrl: string | null;
  mandateApprovalStatus: string;
  created: Date;
  leadCustomer: boolean;
  isNewCustomer: boolean;
  isPoolClient: boolean;
  hasPoolMandate: boolean;
  advisor: ShortAdvisorInfo;
  selected?: boolean;
}

export class ShortAdvisorInfo {
  firstname: string;
  lastname: string;
  id: number;
  profilePictureUrl: string;
}
