<div class="media-preview-desktop-modal-container">
  <div class="modal-body">
    <div class="row">
      <div class="col-10">
        @if (type === 'PDF') {
          <div class="section-title">{{ filename ?? '' }}</div>
        }
      </div>

      <div class="col-2 text-right">
        <i (click)="modalRef.hide()" class="bi bi-x-lg text-20 theme-color pointer-cursor"></i>
      </div>
    </div>

    <div class="row my-15">
      <div class="col-12 position-relative">
        @if (type !== 'OTHER') {
          @if (downloadInProgress) {
            <ngx-skeleton-loader appearance="custom-content" [theme]="{ height: '500px' }"></ngx-skeleton-loader>
          } @else if (type === 'IMAGE') {
            <img class="w-100" [src]="blobUrl" alt="preview" />
          } @else if (type === 'PDF') {
            <pdf-viewer
              [src]="fileBytes"
              [render-text]="false"
              [original-size]="false"
              class="pdf-container"
              [class.loading]="!pdfLoaded"
              (page-rendered)="pdfLoaded = true"
            ></pdf-viewer>
            @if (!pdfLoaded) {
              <ngx-skeleton-loader
                class="pdf-container-skeleton"
                appearance="custom-content"
                [theme]="{ height: '500px' }"
              ></ngx-skeleton-loader>
            }
          }
        }
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <button (click)="download()" class="btn btn-action-secondary btn-rounded">
          <span>
            <i class="bi bi-cloud-arrow-down mr-2"></i>
          </span>
          <span>Download</span>
        </button>
      </div>
    </div>
  </div>
</div>
