import { OnDestroy } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';

type Constructor = new (...args: any[]) => {};

export default function WithSubscription<TBase extends Constructor>(Base: TBase) {
  return class WithSubscription extends Base implements OnDestroy {
    private readonly subscriptions: Subscription[] = [];

    public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    protected listenToSubject<T>(subject: Observable<T>, observer: Partial<Observer<T>> | ((value: T) => void)): void {
      this.subscriptions.push(subject.subscribe(observer as unknown));
    }
  };
}
