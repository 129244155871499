import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { IEditTodo, INewTodo, IPatchTodo, ITodo } from '../model';

@Injectable({
  providedIn: 'root',
})
export class TodoService {
  constructor(private http: HttpClient) {}

  public createTodo(uid, title): Observable<INewTodo> {
    return this.http.post<ITodo>(`${environment.apiEndpoint}/b2b/todos`, { contractUid: uid, title: title });
  }

  public editTodo(todo: IEditTodo): Observable<void> {
    return this.http.put<void>(`${environment.apiEndpoint}/b2b/todos`, todo);
  }

  public patchTodo(todoId: number, todo: IPatchTodo): Observable<void> {
    return this.http.patch<void>(`${environment.apiEndpoint}/b2b/todos/${todoId}`, todo);
  }

  public deleteTodo(todoId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2b/todos/${todoId}`);
  }
}
