import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VinhubTemplateService {
  public isBirthdayClicked: boolean = false;

  public setBirthdayMessage(customerName, advisorName): string {
    return `Alles Gute zum Geburtstag ${customerName}!\n\nViel Gesundheit, Erfolg auf deinem weiteren Weg und mögen deine Wünsche in Erfüllung gehen.\n\nHerzliche Grüße,\n${advisorName}`;
  }
}
