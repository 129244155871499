import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AUTH_SERVICE_TOKEN, AuthService } from '../service';

// Use directive for show/hide content
// *appHasRole="[Roles.Chat ]"

// For change styles e t c
// u need to call this.authService.hasRole(role)  or this.authService.hasAllNeededRoles(this.groups) (for multiple).;
// use these variables for detect roles:

@Directive({
  selector: '[appHasRole]',
})
export class RoleCheckDirective {
  private roles: string[];

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input()
  set appHasRole(roles: string[]) {
    this.roles = roles;
    this.updateView();
  }

  private updateView(): void {
    if (this.authService.hasAllNeededRoles(this.roles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
