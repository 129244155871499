import { TinkCategory } from './tink-category';

export class TransactionDetail {
    id: string;
    accountId: string;
    amount: number;
    categoryId: string;
    tinkCategory: TinkCategory;
    credentialsId: string;
    date: Date;
    description: string;
    formattedDescription: string;
    notes: string;
    originalAmount: number;
    originalDate: Date;
    originalDescription: string;
}