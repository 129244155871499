import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SeoService {
  config = {
    title: 'Vinlivt Business | Mein Vinlivt',
    description: 'Checke deine Vorsorgeaktivitäten und starte mit deiner Altersvorsorge durch.',
    image: 'https://static-assets.vinlivt.de/social-media/vinlivt-share.png',
    site: 'home',
  };

  metaTag = { title: '', description: '', image: '', site: '' };

  constructor(
    private meta: Meta,
    private title: Title,
  ) {}

  public generateTags(conf: any) {
    this.setValues(conf);

    this.title.setTitle(this.metaTag.title);
    this.meta.updateTag({ name: 'description', content: this.metaTag.description });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@vinlivt' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@vinlivt' });
    this.meta.updateTag({ name: 'twitter:title', content: this.metaTag.title });
    this.meta.updateTag({ name: 'twitter:description', content: this.metaTag.description });
    this.meta.updateTag({ name: 'twitter:image', content: `${this.metaTag.image}` });

    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Vinlivt - Vorsorge Co-Pilot' });
    this.meta.updateTag({ property: 'og:locale', content: 'de_DE' });
    this.meta.updateTag({ property: 'og:title', content: this.metaTag.title });
    this.meta.updateTag({ property: 'og:description', content: this.metaTag.description });
    this.meta.updateTag({ property: 'og:image', content: `${this.metaTag.image}` });
    this.meta.updateTag({ property: 'og:url', content: `https://vinlivt.de/#/${this.metaTag.site}` });
  }

  public setValues(conf: any): void {
    if (this.notEmpty(conf.title)) {
      this.metaTag.title = conf.title;
    } else {
      this.metaTag.title = this.config.title;
    }

    if (this.notEmpty(conf.description)) {
      this.metaTag.description = conf.description;
    } else {
      this.metaTag.description = this.config.description;
    }

    if (this.notEmpty(conf.image)) {
      this.metaTag.image = conf.image;
    } else {
      this.metaTag.image = this.config.image;
    }

    if (this.notEmpty(conf.site)) {
      this.metaTag.site = conf.site;
    } else {
      this.metaTag.site = this.config.site;
    }
  }

  public notEmpty(str: string): boolean {
    if (str && typeof str !== 'undefined' && str !== '' && str !== ' ') {
      return true;
    }
    return false;
  }
}
