import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
  ClientsRequest,
  ClientsResponse,
  ClientInviteClientRequest,
  ClientsInvitationRequest,
  ClientInviteClientResponse,
  ClientsInvitationResponse,
  ClientShortInfo,
  ClientsRequestShortInfo,
  ClientsResponseShortInfo,
  Client,
  MultipleClientInvitationRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  public getClients(clientsRequest: ClientsRequest): Observable<ClientsResponse> {
    return this.http.post<ClientsResponse>(`${environment.apiEndpoint}/b2b/clients/list`, clientsRequest);
  }

  public getClientsShortInfo(clientsRequest: ClientsRequestShortInfo): Observable<ClientsResponseShortInfo> {
    return this.http.post<ClientsResponseShortInfo>(
      `${environment.apiEndpoint}/b2b/clients/reduced-list`,
      clientsRequest,
    );
  }

  public openInvites(clientsInvitationRequest: ClientsInvitationRequest): Observable<ClientsInvitationResponse> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/clients/list/invitations`, clientsInvitationRequest);
  }

  public sendInvite(email: string): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2b/clients/invite/${email}`);
  }

  public sendMultipleInvites(body: MultipleClientInvitationRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/b2b/clients/invite/onboarding`, body);
  }

  public reinviteClient(clientInvitationId): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/b2b/clients/reinvite/${clientInvitationId}`, null);
  }

  public cancelInvitation(clientInvitationId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2b/clients/invitation/${clientInvitationId}`);
  }

  public search(query: string): Observable<Client[]> {
    return this.http.get<Client[]>(`${environment.apiEndpoint}/b2b/clients/search/${query}`);
  }

  public getListOfClientInviteClient(request: ClientInviteClientRequest): Observable<ClientInviteClientResponse> {
    let params: HttpParams = new HttpParams()
      .set('page', request.pageable.page.toString())
      .set('size', request.pageable.size.toString())
      .set('sort', request.pageable.sort + ',desc');
    // ,desc for sort it by descending

    return this.http.get<ClientInviteClientResponse>(`${environment.apiEndpoint}/b2b/referral`, { params });
  }

  public getAllClients(clientIds: Array<string>): Observable<ClientShortInfo[]> {
    return this.http.post<ClientShortInfo[]>(`${environment.apiEndpoint}/b2b/clients/reduced-clients-list`, clientIds);
  }

  public checkIfUserDeletable(userId: string): Observable<{
    hasContracts: boolean;
    mandateAvailable: boolean;
  }> {
    return this.http.get<{
      hasContracts: boolean;
      mandateAvailable: boolean;
    }>(`${environment.apiEndpoint}/b2b/person/validate/${userId}`);
  }
}
