export class ProfessionStatusStatistic {
  totalEmployed: number;
  totalContractor: number;
  totalStudent: number;
  totalTrainee: number;
  totalCivilServant: number;
  totalPublicService: number;
  totalNoEmployment: number;
  totalPensioner: number;
}
