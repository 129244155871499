import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, retry } from 'rxjs';

import { environment } from '../../../environments/environment';

import { BrokerMandateInitResponse, BrokerMandate } from '../model';

@Injectable({
  providedIn: 'root',
})
export class BrokerMandateService {
  constructor(private http: HttpClient) {}

  public init(): Observable<BrokerMandateInitResponse> {
    return this.http.get<BrokerMandateInitResponse>(`${environment.apiEndpoint}/b2b/brokermandate/init`);
  }

  public save(brokerMandate: BrokerMandate): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/b2b/brokermandate`, brokerMandate);
  }

  public toggle(brokerMandate: BrokerMandate): Observable<void> {
    return this.http.get<void>(
      `${environment.apiEndpoint}/b2b/brokermandate/toggle/${brokerMandate.id}/${brokerMandate.active}`,
    );
  }

  public pdf(type: string): Observable<any> {
    const url: string = environment.apiEndpoint + '/b2b/brokermandate/pdf/' + type;

    const authHeader: HttpHeaders = new HttpHeaders();
    authHeader.set('Accept', 'application/pdf');

    return this.http
      .get<any>(url, {
        responseType: 'blob' as 'json',
        headers: authHeader,
      })
      .pipe(retry(1));
  }

  public downloadMandate(type: string, id: number): Observable<any> {
    const url: string = environment.apiEndpoint + '/b2b/brokermandate/pdf/' + type + '/' + id;

    const authHeader: HttpHeaders = new HttpHeaders();
    authHeader.set('Accept', 'application/pdf');

    return this.http
      .get<any>(url, {
        responseType: 'blob' as 'json',
        headers: authHeader,
      })
      .pipe(retry(1));
  }
}
