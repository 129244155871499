import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ImageService } from './image.service';

import {
  Advisor,
  AdvisorByRoleResponse,
  AdvisorsRequest,
  AdvisorsResponse,
  Client,
  FileUpload,
  ShortAdvisorInfo,
} from '../model';

import OneTimeRequestCache from '../utils/OneTimeRequestCache';

@Injectable({
  providedIn: 'root',
})
export class AdvisorsService {
  public profileDataChangedSubject = new Subject();
  public activeAdvisorView: string = 'list';

  private readonly advisorProfileCache = new OneTimeRequestCache<Advisor>();

  constructor(
    private readonly http: HttpClient,
    private imageService: ImageService,
  ) {}

  public getAdvisorsByRole(roleName: string, limit: number, offset: number): Observable<AdvisorByRoleResponse> {
    return this.http.get<AdvisorByRoleResponse>(
      `${environment.apiEndpoint}/b2b/advisorrole/list/${roleName}?limit=${limit}&offset=${offset}`,
    );
  }

  public getAdvisors(advisorsRequest: AdvisorsRequest): Observable<AdvisorsResponse> {
    return this.http.post<AdvisorsResponse>(`${environment.apiEndpoint}/b2b/advisor/list`, advisorsRequest);
  }

  public getAdvisorProfile(): Observable<Advisor> {
    return this.advisorProfileCache.request(() =>
      this.http
        .get<Advisor>(`${environment.apiEndpoint}/b2b/advisor/account`)
        .pipe(this.imageService.loadImages('profilePictureUrl', 'company.logoUrl')),
    );
  }

  public getAdvisor(uid: string): Observable<Advisor> {
    return this.http
      .get<Advisor>(`${environment.apiEndpoint}/b2b/advisor/read/${uid}`)
      .pipe(this.imageService.loadImages('profilePictureUrl', 'company.logoUrl'));
  }

  public saveAdvisor(advisor: Advisor): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/advisor/save`, advisor).pipe(
      tap((): void => {
        this.advisorProfileCache.clearCacheIf((value: Advisor): boolean => value.id === advisor.id);
        this.imageService.loadImages('profilePictureUrl', 'company.logoUrl');
      }),
    );
  }

  public deleteAdvisor(uid: string): Observable<AdvisorsResponse> {
    return this.http.delete<AdvisorsResponse>(`${environment.apiEndpoint}/b2b/advisor/delete/${uid}`);
  }

  public assignAdvisorToClient(advisor: ShortAdvisorInfo, client: Client): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2b/advisor/assign/${advisor.id}/${client.id}`);
  }

  public removeAdvisorFromClient(client: Client): Observable<void> {
    return this.http.get<void>(`${environment.apiEndpoint}/b2b/advisor/remove/${client.id}`);
  }

  public upload(file: File): Observable<FileUpload> {
    const formdata: FormData = new FormData();
    formdata.append('file', file, file.name);

    return this.http.post<FileUpload>(`${environment.apiEndpoint}/b2b/advisor/upload/profilepicture`, formdata).pipe(
      tap((): void => {
        this.profileDataChangedSubject.next(true);
        this.advisorProfileCache.clearCache();
      }),
    );
  }

  public deleteProfilePic(): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2b/advisor/profilepicture`).pipe(
      tap((): void => {
        this.profileDataChangedSubject.next(true);
        this.advisorProfileCache.clearCache();
      }),
    );
  }

  public advisorsInvitationText(uid: string): Observable<string> {
    return this.http.get<string>(`${environment.apiEndpoint}/b2b/advisor/invitationtext/${uid}`);
  }
}
