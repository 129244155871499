import { Client } from '../auth/client';

export class ClientsResponse {
  clients: Array<Client> = new Array<Client>();
  offset: number;
  limit: number;
  totalElements: number;
  totalPages: number;
  importInProgress: boolean;
}
