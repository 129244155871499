export class ActivityRequest {
  page: number = 1;
  perPage: number = 30;
  advisorIds: number[] = [];
  eventTypes: string[] = ['ALL'];
}

export class ActivityResponse {
  hasMore: boolean;
  logEvents: ActivityModel[];
}

export class ActivityTypePill {
  name: string;
  type: Array<string>;
  active: boolean;
}

export class ActivityModel {
  createdAt: Date;
  person: ShortPersonModel;
  logEvent:
    | ActivityBirthdayEvent
    | ActivityContractCreate
    | ActivityContractEdit
    | ActivityContractDelete
    | ActivityDamageReport
    | ActivityMandateEvent
    | ActivityPersonCreation
    | ActivityPersonEdit
    | ActivityFinanceCheckCreation
    | ActivityFinanceCheckEdit
    | ActivityRegisterEvent
    | ActivityReminderEvent
    | ActivityTerminationDetailEvent
    | ActivityLeadEvent;
}

export class ShortPersonModel {
  id: number;
  uid: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
}

export class ActivityBirthdayEvent {
  eventType: string;
  clientId: number;
  firstName: string;
  lastName: string;
  description: string;
}

export class ActivityContractCreate {
  eventType: string;
  id: number;
  uid: string;
  title: string;
  description: string;
  createdDate: Date;
  iconUrl: string;
  ownerId: number;
  advisorId: number;
  insuranceProviderId: number;
  insuranceGroupId: number;
}

export class ActivityContractEdit {
  eventType: string;
  id: number;
  uid: string;
  title: string;
  description: string;
  editDate: Date;
  v;
  ownerId: number;
  advisorId: number;
  insuranceProviderId: number;
  insuranceGroupId: number;
}

export class ActivityContractDelete {
  eventType: string;
  id: number;
  title: string;
  description: string;
  editDate: Date;
  iconUrl: string;
  ownerId: number;
  advisorId: number;
  insuranceProviderId: number;
  insuranceGroupId: number;
}

export class ActivityDamageReport {
  eventType: string;
  clientId: number;
  contracUid: string;
  firstName: string;
  lastName: string;
  iconUrl: string;
  contractId: number;
  description: string;
  contractTitle: string;
  reportTitle: string;
}

export class ActivityMandateEvent {
  eventType: string;
  clientId: number;
  firstName: string;
  lastName: string;
  description: string;
  status: string;
}

export class ActivityPersonCreation {
  eventType: string;
  birthday: Date;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  mobile: string;
}

export class ActivityPersonEdit {
  eventType: string;
  birthday: Date;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  mobile: string;
}

export class ActivityFinanceCheckCreation {
  eventType: string;
  birthday: Date;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  description: string;
}

export class ActivityFinanceCheckEdit {
  eventType: string;
  birthday: Date;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  description: string;
}

export class ActivityRegisterEvent {
  eventType: string;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  birthday: Date;
  description: string;
  mobile: string;
}

export class ActivityReminderEvent {
  eventType: string;
  clientId: number;
  firstName: string;
  lastName: string;
  note: string;
  description: string;
}

export class ActivityTerminationDetailEvent {
  eventType: string;
  contractId: number;
  contractUd: string;
  title: string;
  createdDate: Date;
  ownerId: number;
  advisorId: number;
  insuranceProviderId: number;
  insuranceGroupId: number;
  iconUrl: string;
  description: string;
}

export class ActivityLeadEvent {
  eventType: string;
  leadCampaignName: string;
  createdDate: Date;
}

export class ActivityAdvisorsRequest {
  page: number = 1;
  perPage: number = 100;
  companyId: number;
  query: string;
}

export class ActivityAdvisorsResponse {
  hasMore: boolean;
  advisors: ShortAdvisorModel[];
}

export class ShortAdvisorModel {
  id: number;
  uid: string;
  firstName: string;
  lastName: string;
  token: string;
  activitiesAmount: number;
  profilePictureUrl: string;
  selected?: boolean = true;
}
