export class Company {
  id: number;
  name: string;
  logoUrl: string;
  token: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  created: Date;
  showLogoForCustomer: boolean;
  stripeCustomerId: string;
}
