export class UserAccount {
    id: string;
    name: string;
    type: string;
    amount: string;
    currency: string;
    iban: string;
    customerSegment: string;
    currentAmount: number;
}
