import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractUploadService {
  public path: string = environment.apiEndpoint + '/pics/getfile/';
  public contractAttachmentsChangedSubject = new Subject();

  constructor(private http: HttpClient) {}

  // drag and drop
  public uploadFiles(files: File[], contractUid: string): Observable<any> {
    const formdata: FormData = new FormData();
    Array.from(files).forEach((file: File): void => {
      if (file.size / 1024 / 1024 <= 10.0) {
        formdata.append('file', file, file.name);
      }
    });

    return this.http.post<any>(`${environment.apiEndpoint}/b2b/contractsmanager/uploadfiles/${contractUid}`, formdata);
  }

  public uploadFilesForContract(files: File[], contractUid: string, contractType: string): Observable<any> {
    const formdata: FormData = new FormData();

    Array.from(files).forEach((file: File): void => {
      if (file.size / 1024 / 1024 <= 10.0) {
        formdata.append('file', file, file.name);
      }
    });

    return this.http
      .post<any>(
        environment.apiEndpoint + '/b2b/contractsmanager/uploadfiles/' + contractUid + '/' + contractType,
        formdata,
      )
      .pipe(tap(() => this.contractAttachmentsChangedSubject.next(true)));
  }

  public delete(token: string, endpoint: string): Observable<any> {
    return this.http
      .delete<any>(`${environment.apiEndpoint}/b2b/${endpoint}/uploadfiles/delete/${token}`)
      .pipe(tap(() => this.contractAttachmentsChangedSubject.next(true)));
  }
}
