import {} from '../../enum/pension-check/german-states.enum';
import {
  HealthInsuranceStatusEnum,
  ProfessionStatusEnum,
  MobilityStatusEnum,
  HousingSituationStatusEnum,
  RelationshipStatusEnum,
  GermanStatesEnum,
  ProvisionTargetEnum,
  PropertyOwnershipStatusEnum,
  PetStatusEnum,
  MiscStatusEnum,
} from '../../enum';

export class RecipientsFilter {
  id: number;
  filterName: string = '';
  relationshipStatus: RelationshipStatusEnum[] = [];
  professionStatus: ProfessionStatusEnum[] = [];
  // germanStates: GermanStatesEnum[] = [];
  propertyOwnershipStatus: PropertyOwnershipStatusEnum[] = [];
  healthInsuranceStatus: HealthInsuranceStatusEnum[] = [];
  mobilityStatus: MobilityStatusEnum[] = [];
  housingSituationStatus: HousingSituationStatusEnum[] = [];
  provisionTarget: ProvisionTargetEnum[] = [];
  // currentAssetsStatus: CurrentAssetsStatusEnum[] = [];
  // savingPlan: SavingPlanEnum[] = [];
  // riskWillingness: RiskWillingnessEnum[] = [];
  // speculationWillingness: SpeculationWillingnessEnum[] = [];
  // taxClass: TaxClassEnum[] = [];
  // sustainabilityWillingness: SustainabilityWillingnessEnum[] = [];
  ageSelected: boolean = false;
  ageFrom: number;
  ageTo: number;
  childrenSelected: boolean = false;
  children: number;
  // churchTaxApplicable: boolean;
  // netIncomeSelected: boolean = false;
  // netIncomeFrom: number;
  // netIncomeTo: number;
  // debtSelected: boolean = false;
  // debtFrom: number;
  // debtTo: number;
  created: Date;
  selectedFilterEnums: Array<string> = [];
  usedByCampaigns: number;
  misc: Array<MiscStatusEnum> = [];
  pets: Array<PetStatusEnum> = [];
  totalRecipients: number;
}
