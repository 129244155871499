import { RelationShipStatistic } from './relation-ship-statistic';
import { ProfessionStatusStatistic } from './profession-status-statistic';
import { HealthInsuranceStatistic } from './health-insurance-statistic';
import { MobilityStatistic } from './mobility-statistic';
import { HousingSituationStatistic } from './housing-situation-statistic';
import { PropertyOwnerShipStatistic } from './property-owner-ship-statistic';
import { TaxClassStatistic } from './tax-class-statistic';
import { CurrentAssetsStatusStatistic } from './current-assets-status-statistic';
import { SustainabilityWillingnessStatistic } from './sustainability-willingness-statistic';
import { ProvisionStatistic } from './provision-statistic.model';
import { CurrentPetStatistic } from './current-pet-statistic';
import { CurrentMiscStatistic } from './current-misc-statistic';

export class DashboardStatistic {
  totalClients: number;
  registeredSinceYesterday: number;
  registeredWithoutConsent: number;
  registeredWithConsent: number;
  openInvitations: number;
  averageAge: number;
  averageAmountChildren: number;
  relationShipStatusStatistic: FinanceCheckDashboardStatistic[];
  professionStatusStatistic: ProfessionStatusStatistic;
  healthInsuranceStatistic: FinanceCheckDashboardStatistic[];
  mobilityStatistic: FinanceCheckDashboardStatistic[];
  housingSituationStatistic: HousingSituationStatistic;
  propertyOwnerShipStatistic: PropertyOwnerShipStatistic;
  provisionTargetStatistic: FinanceCheckDashboardStatistic[];
  totalFinancialInsuranceContracts: number;
  totalOtherContracts: number;
  totalUploads: number;
  miscStatistic: FinanceCheckDashboardStatistic[];
  petStatistic: FinanceCheckDashboardStatistic[];
  referralsCount: number;
  referralsCountSinceYesteday: number;

  // totalMandates: number;
  // todayMandates: number;
  // diffSinceYesterdayMandates: number;
  brokerMandateStatistic: BrokerMandateStatistic;

  sustainabilityWillingnessStatistic: SustainabilityWillingnessStatistic;
}

export class FinanceCheckDashboardStatistic {
  name: string;
  percentage: number;
  property: string;
  value: number;
  color: string;
}

export class BrokerMandateStatistic {
  today: number;
  total: number;
  yesterday: number;
}
