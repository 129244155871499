import { UserAccount } from '../user-account';

export class ProviderConsentsAccess {

    providerName: string;
    status: string;
    credentialsId: string;
    sessionExpiryDate: number;
    statusUpdated: number;
    accountIds: Array<string> = new Array<string>();
    accounts: Array<UserAccount> = new Array<UserAccount>();
}
