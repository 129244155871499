export enum ProfessionStatusEnum {
  EMPLOYED = 'Angestellter',
  CONTRACTOR = 'Selbständig',
  STUDENT = 'Schüler/Student',
  TRAINEE = 'Azubi',
  CIVIL_SERVANT = 'Beamter',
  PUBLIC_SERVICE = 'Öffentlicher Dienst',
  NO_EMPLOYMENT = 'Keine Beschäftigung',
  PENSIONER = 'Rentner/Pensionär',
}
