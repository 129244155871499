import { APP_INITIALIZER, InjectionToken, Provider } from '@angular/core';
import { TokenProvider } from '@vinlivt/websocket';
import Keycloak from 'keycloak-js';

import { environment } from '../../../environments/environment';

export interface AuthService extends TokenProvider {
  readonly userId: string;
  readonly loggedIn: boolean;

  hasRole(role: string): boolean;

  hasAllNeededRoles(roles: string[]): boolean;

  isInGroup(group: string): boolean;

  hasAllNeededGroup(group: string[]): boolean;

  updatePassword(): Promise<never>;

  register(loginHint: string, referral?: string): Promise<never>;

  logout(): Promise<never>;
}

class KeycloakAuthService implements AuthService {
  private instance: Keycloak | null = null;

  public get userId(): string {
    return this.instance.tokenParsed!.sub!;
  }

  public get loggedIn(): boolean {
    return this.instance?.authenticated ?? false;
  }

  public async init(): Promise<void> {
    this.instance = new Keycloak({
      url: `${environment.apiEndpoint}/auth`,
      // url: `https://api.dev.vinlivt.de/auth`,
      realm: environment.keycloakRealm,
      clientId: 'webapp',
    });

    console.debug('[KeycloakService] Initializing Keycloak');
    await this.instance.init({
      checkLoginIframe: true,
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: location.origin + '/assets/silent-check-sso.html',
      enableLogging: !environment.production,
    });
    console.debug('[KeycloakService] Keycloak initialized');
  }

  public async getToken(): Promise<string> {
    try {
      if (await this.instance.updateToken(20)) {
        console.debug('[KeycloakService] Token updated');
      }
    } catch (e) {
      console.error('[KeycloakService] Failed to update token, fallback to login', e);
      return this.login();
    }

    return this.instance.token;
  }

  public hasRole(role: string): boolean {
    return this.instance?.tokenParsed?.realm_access?.roles?.includes(role) ?? false;
  }

  public hasAllNeededRoles(roles: string[]): boolean {
    return roles.every((role: string) => this.instance.hasRealmRole(role));
  }

  public hasAllNeededGroup(groups: string[]): boolean {
    return groups.some((group: string) => this.isInGroup(group));
  }

  public isInGroup(group: string): boolean {
    return this.instance?.tokenParsed?.groups?.includes(group) ?? false;
  }

  public updatePassword(): Promise<never> {
    return this.login({ action: 'UPDATE_PASSWORD' });
  }

  public register(loginHint: string, referral?: string): Promise<never> {
    const url = new URL(
      this.instance.createLoginUrl({
        action: 'register',
        loginHint: loginHint,
      }),
    );
    if (referral) {
      url.searchParams.append('referral', referral);
    }
    location.assign(url);
    return new Promise(() => {
      // never resolves
    });
  }

  public logout(): Promise<never> {
    const url = new URL(this.instance.createLogoutUrl());
    location.replace(url);
    return new Promise(() => {
      // never resolves
    });
  }

  private login(options?: Keycloak.KeycloakLoginOptions): Promise<never> {
    const url = new URL(this.instance.createLoginUrl(options));
    location.assign(url);
    return new Promise(() => {
      // never resolves
    });
  }
}

export const AUTH_SERVICE_TOKEN = new InjectionToken<AuthService>('AuthService');

export const authServiceProvider: Provider = [
  {
    provide: AUTH_SERVICE_TOKEN,
    useClass: KeycloakAuthService,
    multi: false,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (service: KeycloakAuthService) => () => service.init(),
    multi: true,
    deps: [AUTH_SERVICE_TOKEN],
  },
];
