import { Person } from '../auth/person';
import { Advisor } from '../index';
// import { UserTypeEnum } from '../../enum';
import { Attachment } from '../attachment.model';

export class ChatMessage {
  id: string;
  contractUid: string;
  message: string;
  client: Person;
  advisor: Advisor;
  userType: string;
  created: Date;
  dateLabel: string;
  readAt: Date;
  attachments: Array<Attachment>;
}
