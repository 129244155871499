export class VinhubChatResponse {
  page: number = 0;
  pageSize: number = 3;
  total: number;
  data: VinhubChat[];
  totalPages: number;
}

export class VinhubChat {
  id: number;
  participants: string[];
  type: string;
  reference: string;
  lastMessage: VinhubMessage;
  unreadMessages: number;
}

export class VinhubChatMessages {
  messages: VinhubMessage[];
  nextToken: string;
}

export class VinhubChatMessageResponse {
  page: number = 0;
  pageSize: number = 3;
  total: number;
  data: VinhubMessage[];
  totalPages: number;
}

export class VinhubMessage {
  id: number;
  chatId: number;
  message: string;
  attachments: VinhubAttachment[];
  videoId: number | null;
  authorId: string;
  source: 'USER' | 'SYSTEM';
  createdAt: string;
  dateLabel: string;
  readBy: string[];
}

export class VinhubAttachment {
  id: number;
  type: 'IMAGE' | 'PDF' | 'OTHER';
  filename: string;
  filesize: number;
  fileUrl: string;
  previewUrl: string;
}

export class VinhubSendMessage {
  message: string | null = null;
  attachments: VinhubAttachmentForSend[] | null = null;
  videoId: number | null = null;
}

export class VinhubAttachmentForSend {
  type: string;
  filename: string;
  filesize: number;
}

export class ReadMessages {
  chatId: number;
  messageIds: number[];
  readBy: string;
}

export class VinhubChatHistoryRequest {
  chatId: number;
  from: string;
  to: string;
  attachmentsOnly: boolean;
}

export class VinhubChatHistoryResponse {
  chatId: number;
  userId: string;
  status: string;
  startDate: string;
  endDate: string;
  s3Url: string;
}
