import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import * as chatData from '../../../assets/chats.json';
import { VinhubChatResponse } from '../model';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  public isRedirectedFromNews: boolean = false;
  public data: any = chatData;

  constructor(private http: HttpClient) {}

  public latestChatMessages(
    page: number = 0,
    page_size: number = 50,
    participant?: string,
    unread: boolean = false,
  ): Observable<VinhubChatResponse> {
    let params: HttpParams = new HttpParams();

    // Add page if available
    if (page) {
      params = params.set('page', page.toString());
    }

    // Add pageSize if available
    if (page_size) {
      params = params.set('page_size', page_size.toString());
    }

    // Add participant if available
    if (participant) {
      params = params.set('participant', participant.toString());
    }

    params = params.set('unread', unread).set('empty_chats', false).set('group_by_participant', true);

    let options: {} = { params };
    return this.http.get<VinhubChatResponse>(`${environment.apiEndpoint}/chat/b2b/entity`, options);
  }
}
