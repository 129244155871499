import { Component, OnInit } from '@angular/core';

import { NavigationStart, Router } from '@angular/router';

import { SeoService } from './shared/service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private seoService: SeoService,
  ) {}

  public ngOnInit(): void {
    this.router.events.subscribe((event): void => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });

    this.seoService.generateTags({
      title: 'Vinlivt Business | Mein Vinlivt Business',
    });
  }
}
