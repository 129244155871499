import { Company } from './company';
import { AdvisorByRoleModel } from './advisor-models.model';

export class Advisor {
  id: number;
  uid: string;
  email: string;
  firstname: string;
  lastname: string;
  created: Date;
  profilePictureUrl: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  calendarLink: string;
  profilePicture: string;
  phoneNumber: string;
  personType: string;
  officePhoneNumber: string;
  company: Company;
  trainee: boolean;
  pin: string;
  personalInvitationText: string;
  supervisor: AdvisorByRoleModel;
}
