import { AfterViewInit, Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { environment } from '../../../../environments/environment';

import { ClientService } from '../../service';

import { Advisor, ErrorResponse } from '../../model';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  standalone: true,
  selector: 'app-client-invitation-modal',
  templateUrl: 'client-invitation-modal.component.html',
  styleUrls: ['client-invitation-modal.component.scss'],
  imports: [NgOptimizedImage, FormsModule, NgClass, NgxSkeletonLoaderModule],
})
export class ClientInvitationModalComponent implements AfterViewInit {
  protected link: string = '';
  protected loading: boolean = true;

  public advisor: Advisor = new Advisor();
  public emails: string = '';
  public validEmails: Array<string> = [];
  public sendingEmails: boolean = false;
  public allEmailsSend: boolean = false;
  public isEmailValid: boolean = false;
  public duplicatedEmails: string[] = [];
  public loaders: Array<number> = [];

  constructor(
    public modalRef: BsModalRef,
    private toasterService: ToastrService,
    private clientService: ClientService,
  ) {}

  public ngAfterViewInit(): void {
    setTimeout((): void => {
      this.link = `${environment.appUrl}/register?referral=uid:${this.advisor.uid}`;
      this.loading = false;
    }, 1000);
  }

  protected copyToClipBoard(text: string, showToastr: boolean = false): void {
    navigator.clipboard.writeText(text);

    if (showToastr) {
      this.toasterService.info('In Zwischenablage kopiert');
    }
  }

  protected checkEmail(event): void {
    const emailsArray = event.target.value.split(',');
    this.isEmailValid = emailsArray.every((email) => {
      email = email.trim();
      return this.validateEmail(email);
    });
  }

  protected inviteClients(): void {
    // preparing emails
    this.sendingEmails = true;
    this.duplicatedEmails = [];
    const emailArray: string[] = this.emails.split(',');

    emailArray.forEach((mail: string): void => {
      // validate emails - filter out
      const email: string = mail.replace(/\s/g, '');
      if (this.validateEmail(email)) {
        this.validEmails.push(email);
        this.loaders.push(0);
      }
    });

    for (const i in this.loaders) {
      this.sendInvite(this.validEmails[i]);
      this.simulateLoader(i);
    }

    if (!this.duplicatedEmails?.length) {
      this.modalRef.hide();
    }

    this.allEmailsSend = true;
    this.isEmailValid = false;
  }

  protected async simulateLoader(loader: any): Promise<void> {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));

    let index: number = 0;
    while (index <= 100) {
      index += Math.floor(Math.random() * (15 - 1 + 1)) + 1;
      this.loaders[loader] = index;

      await timer(16 * this.validEmails.length);
    }
  }

  private sendInvite(email: string): void {
    this.clientService.sendInvite(email).subscribe({
      next: (): void => {
        this.emails = '';
        this.toasterService.info('Einladung wurde erfolgreich versendet.');
      },
      error: (err: ErrorResponse): void => {
        if (err.status === 409) {
          this.duplicatedEmails.push(email);
          this.toasterService.error(
            'Nutzer ist bereits eingeladen, bitte schauen Sie unter “Offene Kundeneinladungen”.',
          );
        }
        if (err.status === 500) {
          this.toasterService.error('Kunde mit dieser Emailadresse existiert bereits.');
        }
      },
    });
  }

  private validateEmail(email: string): boolean {
    const emailCheck: RegExp =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  }
}
