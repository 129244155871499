import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'events' })
export class CalendarEventPipe implements PipeTransform {
    transform(arr, args: string[]): any {
        arr.sort(function(a, b){
            if(a.cssClass < b.cssClass) { return -1; }
            if(a.cssClass > b.cssClass) { return 1; }
            return 0;
        })
        return arr;
    }
}