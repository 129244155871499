import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { PersonProfile } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PersonProfileService {
  constructor(private http: HttpClient) {}

  public loadPersonProfile(): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/profile/load`);
  }

  public edit(pensionCheck: PersonProfile): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/profile/save`, pensionCheck);
  }

  public delete(): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2b/profile/delete`);
  }
}
