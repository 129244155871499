import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { PersonProfile } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PensionCheckService {
  constructor(private http: HttpClient) {}

  public loadPensionCheck(clientId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/pensioncheck/load/${clientId}`);
  }

  public edit(pensionCheck: PersonProfile): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/pensioncheck/edit`, pensionCheck);
  }
}
