import { Component, OnInit } from '@angular/core';
import { DatePipe, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';

import { VerifyMandateModalComponent } from '../verify-mandate-modal/verify-mandate-modal.component';

import { SharedModule } from '../../shared.module';

import { BrokerMandateApprovalService } from '../../service';
import { WebSocketService } from '@vinlivt/websocket';

import { BrokerMandateApprovalModel } from '../../model';

import { Roles } from '../../enum/roles.enum';

import WithSubscription from '../../utils/WithSubscription';

@Component({
  standalone: true,
  selector: ' app-broker-mandate-modal',
  templateUrl: './broker-mandate-modal.component.html',
  styleUrls: ['./broker-mandate-modal.component.scss'],
  imports: [NgOptimizedImage, SharedModule, DatePipe, RouterLink, VerifyMandateModalComponent],
})
export class BrokerMandateModalComponent extends WithSubscription(Object) implements OnInit {
  protected status: string = null;
  protected totalPending: { count: number } = { count: 0 };
  protected brokerMandateApprovalList: Array<BrokerMandateApprovalModel>;

  constructor(
    private webSocketService: WebSocketService,
    private brokerMandateApprovalService: BrokerMandateApprovalService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.countPendingMandates();
    this.listenToNewMandates();
  }

  protected countPendingMandates(): void {
    this.brokerMandateApprovalService.countPending().subscribe({
      next: (data: { count: number }): void => {
        this.totalPending = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected loadPendingMandates(): void {
    this.brokerMandateApprovalService.pending().subscribe({
      next: (data: BrokerMandateApprovalModel[]): void => {
        this.brokerMandateApprovalList = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  private listenToNewMandates(): void {
    this.listenToSubject(this.webSocketService.subscribe<any>('legacy/new-mandate-request'), (data: any): void => {
      this.playSound();
      // this.toasterService.info('Neues Mandat wurde gerade unterschrieben!');
      this.countPendingMandates();
    });
  }

  private playSound(): void {
    const audio = new Audio();
    audio.src = 'assets/sounds/pick.mp3';
    audio.load();
    audio.play();
  }

  protected readonly setTimeout = setTimeout;
  protected readonly Roles = Roles;
}
