import { RecipientsFilter } from '../campaign/recipients-filter';
import { Campaign } from '../campaign/campaign';

export class RecipientsRequest {
  offset: number = 0;
  limit: number = 25;
  totalPages: number;
  sortField: string = 'lastName';
  ascendingSorting: boolean = true;
  query: string;
  campaign: Campaign;
  recipientsFilter?: RecipientsFilter = new RecipientsFilter();
}
