import { ClientSettings } from '../auth/client-settings';
import { BrokerMandateApprovalModel } from '../broker-mandate-approval.model';

export class ClientDetailResponse {
  client: ClientDetails;
  newContractMessages: number;
  consentApproved: boolean;
  personBankAccounts: PersonBankAccountModel[];
}

export class PersonBankAccountModel {
  iban: string;
  bic: string;
  bankName: string;
}

export class ClientDetails {
  id: number;
  uid: string;
  uuid: string;
  email: string;
  emailVerified: boolean;
  firstname: string;
  lastname: string;
  token: string;
  profilePictureUrl: string | null;
  clientSettings: ClientSettings;
  street: string;
  houseNumber: string;
  zip: number;
  city: string;
  mobile: string;
  selected?: boolean;
  brokerMandateApproval: BrokerMandateApprovalModel;
  isEnabled?: boolean;
  isPoolClient?: boolean;
  hasPoolMandate?: boolean;
  poolCrmLink: string;
  birthday: Date | string;
  age: number;
  taxId: string;
  activeConversation: boolean;
  advisorId: number;
}
