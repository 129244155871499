import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-reset-mandate-status-modal',
  templateUrl: 'reset-mandate-status-modal.component.html',
  styleUrls: ['reset-mandate-status-modal.component.scss'],
  imports: [NgOptimizedImage],
})
export class ResetMandateStatusModalComponent {
  @Output() setApprovedStatus = new EventEmitter<boolean>();

  constructor(protected bsModalRef: BsModalRef) {}
}
