export class ClientInvitation {
  id: number;
  email: string;
  created: Date;
  reInvitedAt: Date;
  referralName: string;
  referralPictureUrl: string;
  invitationCounter: number;
}

export class MultipleClientInvitationRequest {
  clientIds: number[] = [];
  personalInvite: boolean = false;
  personalText: string;
}
