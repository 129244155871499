import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
  Campaign,
  CampaignRequest,
  CampaignResponse,
  CampaignDetail,
  CampaignDashboard,
  CampaignRecipientRequest,
  CampaignRecipientResponse,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(private http: HttpClient) {}

  public saveCampaign(campaign: Campaign) {
    return this.http.post(`${environment.apiEndpoint}/b2b/campaign`, campaign);
  }

  public save(payload: Campaign, files: File[]): Observable<Campaign> {
    const formData: FormData = new FormData();

    const payloadBlob = new Blob([JSON.stringify(payload)], {
      type: 'application/json',
    });

    formData.append('payload', payloadBlob);
    if (files) {
      Array.from(files).forEach((file) => {
        if (file.size / 1024 / 1024 <= 10.0) {
          formData.append('file', file, file.name);
        }
      });
    }

    return this.http.post<Campaign>(`${environment.apiEndpoint}/b2b/campaign/save`, formData);
  }

  public loadAll(request: CampaignRequest): Observable<CampaignResponse> {
    return this.http.post<CampaignResponse>(`${environment.apiEndpoint}/b2b/campaign/list`, request);
  }

  public load(id: string): Observable<Campaign> {
    return this.http.get<Campaign>(`${environment.apiEndpoint}/b2b/campaign/${id}`);
  }

  public deleteAttachment(token: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2b/campaign/attachment/delete/${token}`);
  }

  public delete(campaign: Campaign): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2b/campaign/${campaign.id}`);
  }

  public archive(campaign: Campaign): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/campaign/archive/${campaign.id}`);
  }

  public loadDetails(campaignId: string): Observable<CampaignDetail> {
    return this.http.get<CampaignDetail>(`${environment.apiEndpoint}/b2b/campaign/details/${campaignId}`);
  }

  public loadDashboard(): Observable<CampaignDashboard> {
    return this.http.get<CampaignDashboard>(`${environment.apiEndpoint}/b2b/campaign/dashboard`);
  }

  public getRecipients(request: CampaignRecipientRequest): Observable<CampaignRecipientResponse> {
    return this.http.post<CampaignRecipientResponse>(`${environment.apiEndpoint}/b2b/campaignrecipients`, request);
  }
}
