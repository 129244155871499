import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ClientDetails } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor(private http: HttpClient) {}

  public saveOrEdit(client: ClientDetails): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/person`, client);
  }

  public deletePerson(id: number): Observable<any> {
    return this.http.delete(`${environment.apiEndpoint}/b2b/person/${id}`);
  }
}
