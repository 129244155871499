import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, tap } from 'rxjs';

import { environment } from '../../../environments/environment';

import { DataOfficerRequest, Company, FileUpload } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public companyDataChangedSubject = new Subject();

  constructor(private http: HttpClient) {}

  public getCompanyData(): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/company/data`);
  }

  public getCompanyQR(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  public saveCompany(company: Company): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/company/data`, company);
  }

  public upload(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file, file.name);

    return this.http
      .post<FileUpload>(`${environment.apiEndpoint}/b2b/company/upload/logo`, formdata)
      .pipe(tap(() => this.companyDataChangedSubject.next(true)));
  }

  public deleteLogo(): Observable<any> {
    return this.http
      .delete<any>(`${environment.apiEndpoint}/b2b/company/logo`)
      .pipe(tap(() => this.companyDataChangedSubject.next(true)));
  }

  public showLogo(): Observable<any> {
    return this.http.get(`${environment.apiEndpoint}/b2b/company/showlogo`);
  }

  public companyLogoUrl(companyId: number): Observable<any> {
    return this.http.get(`${environment.apiEndpoint}/b2b/company/logo/${companyId}`);
  }

  public saveCompanyDataOfficer(request: DataOfficerRequest): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/companysettings/dataofficer`, request);
  }
}
