<div class="wrapper" [ngClass]="{ shadowed: isOpen, 'layout-vinhub-tablet': hideHeader, 'hide-header': hideHeader }">
  <app-header
    (open)="showMobileUserSidebar($event)"
    [firstLayer]="firstLayer"
    [title]="deepDiveTitle"
    class="header"
  ></app-header>

  <app-sidebar (click)="hideMobileUserSidebar(false)" class="sidebar"></app-sidebar>

  <main (click)="hideMobileUserSidebar(false)" [ngClass]="{ 'body-deepDive': !hideHeader }" class="body">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
</div>

@if (isOpen) {
  <app-user-sidebar (close)="hideMobileUserSidebar($event)" [isOpen]="isOpen"></app-user-sidebar>
}

@if (firstLayer) {
  <app-mobile-bottom-navigation class="bottom-nav"></app-mobile-bottom-navigation>
}
