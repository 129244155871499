import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  constructor(private http: HttpClient) {}

  public saveReminder(body): Observable<any> {
    return this.http.post(`${environment.apiEndpoint}/b2b/reminder`, body);
  }

  public getListOfReminders(): Observable<any> {
    return this.http.get(`${environment.apiEndpoint}/b2b/reminder`);
  }

  public deleteReminder(id: number): Observable<any> {
    return this.http.delete(`${environment.apiEndpoint}/b2b/reminder/${id}`);
  }
}
