import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

import {
  CompanyStripeModel,
  CustomerProductsModel,
  CustomerProductsOverviewModel,
  GroupedAdvisorsToAssignedProductsModel,
  PaymentMethodModel,
  PaymentRequestModel,
  ProductsSummaryModel,
  ProductsSummaryRequestBody,
  StripeInvoiceByCriteriaRequestModel,
  StripeInvoiceByCriteriaResponseModel,
  StripeInvoiceResponseModel,
  StripeSubscriptionCreationRequest,
  SubscriptionInfoModel,
  SubscriptionModel,
  SubscriptionPermissionsModel,
  SubscriptionPreviewRequestModel,
  SubscriptionPreviewResponseModel,
  SubscriptionProductModel,
  SubscriptionUpdateRequestModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionManagerService {
  constructor(private http: HttpClient) {}

  public getAdvisorsGroupedByProducts(
    customerStripeId: string,
    type: 'PACKAGE' | 'ADD_ON' | 'LEAD',
  ): Observable<GroupedAdvisorsToAssignedProductsModel[]> {
    return this.http.get<GroupedAdvisorsToAssignedProductsModel[]>(
      `${environment.apiEndpoint}/subscription/b2b/advisor/company/${customerStripeId}/product/${type}`,
    );
  }

  public getAdvisorsProductsSummary(
    customerStripeId: string,
    body: ProductsSummaryRequestBody,
  ): Observable<ProductsSummaryModel[]> {
    return this.http.post<ProductsSummaryModel[]>(
      `${environment.apiEndpoint}/subscription/b2b/advisor/${customerStripeId}/summary`,
      body,
    );
  }

  public getCustomerFromStripe(customerStripeId: string): Observable<CompanyStripeModel> {
    return this.http.get<CompanyStripeModel>(
      `${environment.apiEndpoint}/subscription/b2b/customer/${customerStripeId}`,
    );
  }

  public updateCustomerInfoInStripe(
    customerStripeId: string,
    body: Partial<CompanyStripeModel>,
  ): Observable<CompanyStripeModel> {
    return this.http.put<CompanyStripeModel>(
      `${environment.apiEndpoint}/subscription/b2b/customer/${customerStripeId}`,
      body,
    );
  }

  public createCustomerInStripe(body: { name: string }): Observable<{ stripeCustomerId: string }> {
    return this.http.post<{ stripeCustomerId: string }>(`${environment.apiEndpoint}/subscription/b2b/customer`, body);
  }

  public getCustomerProducts(customerStripeId: string): Observable<CustomerProductsModel[]> {
    return this.http.get<CustomerProductsModel[]>(
      `${environment.apiEndpoint}/subscription/b2b/customer/${customerStripeId}/products`,
    );
  }

  public getCustomerProductsOverview(customerStripeId: string): Observable<CustomerProductsOverviewModel[]> {
    return this.http.get<CustomerProductsOverviewModel[]>(
      `${environment.apiEndpoint}/subscription/b2b/customer/${customerStripeId}/products/overview`,
    );
  }

  public getSubscriptionByCustomerStripeId(customerStripeId: string): Observable<SubscriptionModel> {
    return this.http.get<SubscriptionModel>(
      `${environment.apiEndpoint}/subscription/b2b/customer/${customerStripeId}/subscription`,
    );
  }

  public getCustomerPaymentMethods(companyStripeId: string): Observable<PaymentMethodModel[]> {
    return this.http.get<PaymentMethodModel[]>(
      `${environment.apiEndpoint}/subscription/b2b/customer/${companyStripeId}/card-details`,
    );
  }

  public removeCustomerPaymentMethod(companyStripeId: string, paymentMethodId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/subscription/b2b/customer/${companyStripeId}/payment-details/${paymentMethodId}/detach`,
    );
  }

  public getStripePaymentMethodsIframe(companyStripeId: string): Observable<{ responseUrl: string }> {
    return this.http.get<{ responseUrl: string }>(
      `${environment.apiEndpoint}/subscription/b2b/customer/${companyStripeId}/payment-details`,
    );
  }

  public getListOfStripeInvoices(
    customerStripeId: string,
    perPage: number,
    startingAfter?: string,
    startingBefore?: string,
  ): Observable<StripeInvoiceResponseModel> {
    let params: HttpParams = new HttpParams();

    // for showing a page we need to add 2 arrows for pagination
    // per page is a amount of returned items
    // for control next page we need to push last id of invoice to startingAfter
    // for control prev page we need to push first id of invoice to startingBefore

    if (perPage) {
      params = params.set('perPage', perPage.toString());
    }

    if (startingAfter) {
      params = params.set('startingAfter', startingAfter);
    }

    if (startingBefore) {
      params = params.set('startingBefore', startingBefore);
    }

    let options: {} = { params };
    return this.http.get<StripeInvoiceResponseModel>(
      `${environment.apiEndpoint}/subscription/b2b/invoice/customer/${customerStripeId}`,
      options,
    );
  }

  public filterInvoiceByCriteria(
    body: StripeInvoiceByCriteriaRequestModel,
  ): Observable<StripeInvoiceByCriteriaResponseModel> {
    return this.http.post<StripeInvoiceByCriteriaResponseModel>(
      `${environment.apiEndpoint}/subscription/b2b/invoice/filter`,
      body,
    );
  }

  public assignPermissionsToUser(body: SubscriptionPermissionsModel): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/subscription/b2b/permissions`, body);
  }

  public removePermissionsFromUser(body: SubscriptionPermissionsModel): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/subscription/b2b/permissions/remove`, body);
  }

  public handleStripePaymentRequest(body: PaymentRequestModel): Observable<{ checkoutUrl: string }> {
    return this.http.post<{ checkoutUrl: string }>(
      `${environment.apiEndpoint}/subscription/b2b/stripe/checkout/payment`,
      body,
    );
  }

  public handleStripeSubscriptionCreation(
    body: StripeSubscriptionCreationRequest,
  ): Observable<{ checkoutUrl: string }> {
    return this.http.post<{ checkoutUrl: string }>(
      `${environment.apiEndpoint}/subscription/b2b/stripe/checkout/subscription`,
      body,
    );
  }

  public getAllProducts(): Observable<SubscriptionProductModel[]> {
    return this.http.get<SubscriptionProductModel[]>(`${environment.apiEndpoint}/subscription/b2b/products`);
  }

  public handleStripePaymentsRequest(body: string[]): Observable<void> {
    // TODO: Stripe signature HTTP header to body ?
    return this.http.post<void>(`${environment.apiEndpoint}/subscription/stripe/payment`, body);
  }

  public previewOfSubscription(body: SubscriptionPreviewRequestModel): Observable<SubscriptionPreviewResponseModel> {
    return this.http.post<SubscriptionPreviewResponseModel>(
      `${environment.apiEndpoint}/subscription/b2b/base/preview`,
      body,
    );
  }

  public getSubscriptionId(stripeId: string): Observable<SubscriptionInfoModel> {
    return this.http.get<SubscriptionInfoModel>(
      `${environment.apiEndpoint}/subscription/b2b/base/customer/${stripeId}`,
    );
  }

  public updateStripeSubscription(
    subscriptionId: string,
    body: SubscriptionUpdateRequestModel,
  ): Observable<SubscriptionModel> {
    return this.http.patch<SubscriptionModel>(
      `${environment.apiEndpoint}/subscription/b2b/base/${subscriptionId}`,
      body,
    );
  }
}
