import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { SpendingsRequest } from '../model';

@Injectable({
  providedIn: 'root',
})
export class SpendingsService {
  constructor(private http: HttpClient) {}

  public categoryTransactions(spendingsRequest: SpendingsRequest): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/b2b/spendings/category`, spendingsRequest);
  }

  public contracts(): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/b2b/spendings/contracts`);
  }
}
