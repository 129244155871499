import { Client } from './auth/client';
import { ClientDetails } from './response/client-detail-response';

export class BrokerMandateApprovalModel {
  id: number;
  status: string;
  person: ClientDetails;
  created: Date;
  approvedAt: Date;
  submittedToPool: boolean;
}
