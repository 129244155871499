import { RelationshipStatusEnum } from '../../enum/pension-check/relationship-status.enum';
import { ProfessionStatusEnum } from '../../enum/pension-check/profession-status.enum';
import { HousingSituationStatusEnum } from '../../enum/pension-check/housing-situation-status.enum';
import { PropertyOwnershipStatusEnum } from '../../enum/pension-check/property-ownership-status.enum';
import { CurrentAssetsStatusEnum } from '../../enum/pension-check/current-assets-status.enum';
import { ProvisionTargetEnum } from '../../enum/pension-check/provision-target.enum';
import { SavingPlanEnum } from '../../enum/pension-check/saving-plan.enum';
import { RiskWillingnessEnum } from '../../enum/pension-check/risk-willingness.enum';
import { SpeculationWillingnessEnum } from '../../enum/pension-check/speculation-willingness.enum';
import { HealthInsuranceStatusEnum } from '../../enum/pension-check/health-insurance-status.enum';
import { MobilityStatusEnum } from '../../enum/pension-check/mobility-status.enum';
import { GermanStatesEnum } from '../../enum/pension-check/german-states.enum';
import { SustainabilityWillingnessEnum } from '../../enum/pension-check/sustainability-willingness.enum';
import { TaxClassEnum } from '../../enum/pension-check/tax-class.enum';

export class PersonProfile {
  id: number;
  relationshipStatus: RelationshipStatusEnum;
  professionStatus: ProfessionStatusEnum;
  healthInsuranceStatus: HealthInsuranceStatusEnum;
  churchTaxApplicable: boolean;
  netIncome: number;
  childrenAmount: number = 0;
  applicableChildrenSupport: number = 0;
  accountableChildrenAmount: number = 0;
  mobilityStatus: Array<MobilityStatusEnum> = [];
  housingSituation: HousingSituationStatusEnum;
  germanState: GermanStatesEnum = null;
  propertyOwnership: Array<PropertyOwnershipStatusEnum> = [];
  provisionTarget: Array<ProvisionTargetEnum> = [];
  currentAssetsStatus: CurrentAssetsStatusEnum;
  savingPlan: SavingPlanEnum;
  availableDebts: boolean;
  debtsAmount: number;
  monthlyCharge: number;
  riskWillingness: RiskWillingnessEnum;
  speculationWillingness: SpeculationWillingnessEnum;
  sustainabilityWillingness: SustainabilityWillingnessEnum;
  taxClass: TaxClassEnum;
}
