import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { CustomerLeadModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CustomerLeadService {
  constructor(private http: HttpClient) {}

  public cancelCustomerLead(body: CustomerLeadModel): Observable<CustomerLeadModel> {
    return this.http.post<CustomerLeadModel>(`${environment.apiEndpoint}/lead/customerlead/cancel`, body);
  }
}
