import { Contract } from '../contractsmanager/contract';
import { ClientChat } from '../contractsmanager/client-chat';

export class CommunicationContractResponse {
  contracts: Array<Contract> = new Array<Contract>();
  unreadMessages: number;
  inactiveContracts: Array<Contract> = new Array<Contract>();
  activeContracts: Array<Contract> = new Array<Contract>();
  clientChat: ClientChat = new ClientChat();
}
