import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { CalendarRequest, CalendarData } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: HttpClient) {}

  public loadAll(request: CalendarRequest): Observable<CalendarData[]> {
    return this.http.post<CalendarData[]>(`${environment.apiEndpoint}/b2b/calendar/list`, request);
  }

  public saveEvent(request): Observable<any> {
    return this.http.post(`${environment.apiEndpoint}/b2b/calendar/save`, request);
  }

  public getCalendarEntry(id: number): Observable<any> {
    return this.http.get(`${environment.apiEndpoint}/b2b/calendar/${id}`);
  }

  public deleteEvent(id: number): Observable<any> {
    return this.http.delete(`${environment.apiEndpoint}/b2b/calendar/${id}`);
  }
}
