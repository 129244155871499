import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  public upload(files: File[]): Observable<any> {
    const formdata: FormData = new FormData();

    Array.from(files).forEach((file) => {
      if (file.size / 1024 / 1024 <= 10.0) {
        formdata.append('file', file, file.name);
      }
    });

    return this.http.post<any>(`${environment.apiEndpoint}/b2b/filemanager/meta`, formdata);
  }
}
