export class CustomerListResponse {
  totalCount: number;
  totalCountPrecision: string;
  offset: number;
  nextUrl: string;
  selfUrl: string;
  defaultPageSize: number;
  maxPageSize: number;
  pageKey: string;
  nextPageKey: string;
  pageSize: number;
}
