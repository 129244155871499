import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, tap } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ContractAdvice } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ContractAdviceService {
  public contractAdviceChangedSubject = new Subject();

  constructor(private http: HttpClient) {}

  public load(contractUid: string): Observable<ContractAdvice> {
    return this.http.get<ContractAdvice>(`${environment.apiEndpoint}/b2b/contractadvice/${contractUid}`);
  }

  public delete(contractId: number, adviceId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/b2b/contractadvice/${contractId}/${adviceId}`);
  }

  public saveAdvice(contractAdvice: ContractAdvice): Observable<any> {
    return this.http
      .post<any>(`${environment.apiEndpoint}/b2b/contractadvice`, contractAdvice)
      .pipe(tap((): void => this.contractAdviceChangedSubject.next(true)));
  }
}
