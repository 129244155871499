export class ClientsInvitationRequest {
  offset: number = 0;
  limit: number = 150;
}

export class ClientInviteClientRequest {
  pageable: ClientInviteClientPageable = new ClientInviteClientPageable();
}

export class ClientInviteClientPageable {
  page: number = 0;
  size: number = 20;
  sort: string = 'created';
}
