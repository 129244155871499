import { GermanStatesEnum } from '../enum/pension-check/german-states.enum';
import { HealthInsuranceStatusEnum } from '../enum/pension-check/health-insurance-status.enum';
import { HousingSituationStatusEnum } from '../enum/pension-check/housing-situation-status.enum';
import { MobilityStatusEnum } from '../enum/pension-check/mobility-status.enum';
import { ProfessionStatusEnum } from '../enum/pension-check/profession-status.enum';
import { PropertyOwnershipStatusEnum } from '../enum/pension-check/property-ownership-status.enum';
import { ProvisionTargetEnum } from '../enum/pension-check/provision-target.enum';
import { RelationshipStatusEnum } from '../enum/pension-check/relationship-status.enum';
import { SustainabilityWillingnessEnum } from '../enum/pension-check/sustainability-willingness.enum';
import { TaxClassEnum } from '../enum/pension-check/tax-class.enum';
import { CurrentAssetsStatusEnum } from '../enum/pension-check/current-assets-status.enum';
import { SavingPlanEnum } from '../enum/pension-check/saving-plan.enum';
import { RiskWillingnessEnum } from '../enum/pension-check/risk-willingness.enum';
import { SpeculationWillingnessEnum } from '../enum/pension-check/speculation-willingness.enum';
import { PetStatusEnum } from '../enum/pension-check/pet-status.enum';
import { MiscStatusEnum } from '../enum/pension-check/misc-status.enum';

export const groupFilteredData = [
  {
    name: 'Beziehung',
    filter: RelationshipStatusEnum,
  },
  {
    name: 'Berufsstatus',
    filter: ProfessionStatusEnum,
  },
  // {
  //   name: 'Bundesland',
  //   filter: GermanStatesEnum,
  // },
  {
    name: 'Immobilien',
    filter: PropertyOwnershipStatusEnum,
  },
  {
    name: 'Krankenversicherung',
    filter: HealthInsuranceStatusEnum,
  },
  {
    name: 'Mobiliät',
    filter: MobilityStatusEnum,
  },
  {
    name: 'Wohnsituation',
    filter: HousingSituationStatusEnum,
  },
  {
    name: 'Vorsorgeziele',
    filter: ProvisionTargetEnum,
  },
  {
    name: 'Haustiere',
    filter: PetStatusEnum,
  },
  {
    name: 'Weiterer Besitz',
    filter: MiscStatusEnum,
  },
  // {
  //   name: 'Vermögen',
  //   filter: CurrentAssetsStatusEnum,
  // },
  // {
  //   name: 'Vorsorgeanteil',
  //   filter: SavingPlanEnum,
  // },
  // {
  //   name: 'Risikobereitschaft',
  //   filter: RiskWillingnessEnum,
  // },
  // {
  //   name: 'Investitionsbereitschaft',
  //   filter: SpeculationWillingnessEnum,
  // },
  // {
  //   name: 'Steuerklasse',
  //   filter: TaxClassEnum,
  // },
  // {
  //   name: 'Nachhaltigkeit',
  //   filter: SustainabilityWillingnessEnum,
  // },
];
