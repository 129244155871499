export class AdvisorByRoleResponse {
  offset: number;
  limit: number;
  totalPages: number;
  totalItems: number;
  companyStripeId: string;
  advisors: AdvisorByRoleModel[];
}

export class AdvisorByRoleModel {
  id: number;
  userId: string;
  token: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
  productNames?: string[];
  price?: number;
  subscriptionPeriod?: string;
}

export class CombinedAdvisorRolesTable {
  requestString: string;
  offset: number;
  response: AdvisorByRoleResponse;
  title: string;
}
