import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsManagerService {
  private readonly readMessageSubject = new Subject<void>();

  constructor() {}

  public readMessage(): void {
    this.readMessageSubject.next();
  }

  public observeReadMessage(): Observable<void> {
    return this.readMessageSubject.asObservable();
  }
}
